import { registerQuery } from 'data-fetching-client';
import { getEcosystemClient } from 'marketplace-ui-common/clients/ecosystemClient';
export const GET_REVIEWS = registerQuery({
  fieldName: 'reviewsResponse',
  args: ['appContext', 'queries', 'reviewType'],
  fetcher({
    appContext,
    queries,
    reviewType
  }) {
    const client = getEcosystemClient(appContext);
    return client.post('reviews/search', {
      data: Object.assign({}, queries, {
        reviewTypes: [reviewType]
      })
    });
  }
});