export const HUBSPOTTER = 'HUBSPOTTER';
export const LIMIT_REACHED = 'LIMIT_REACHED';
export const PROVIDER_USER = 'PROVIDER_USER';
export const TEST_PORTAL_USER = 'TEST_PORTAL_USER';
export const ALREADY_REVIEWED = 'ALREADY_REVIEWED';
export const PUBLIC_USER = 'PUBLIC_USER';
export const NOT_INSTALLED = 'NOT_INSTALLED';
export const UNINSTALLED = 'UNINSTALLED';
export const EMPTY_PORTAL = 'EMPTY_PORTAL';
export const MIN_PORTAL_AGE = 'MIN_PORTAL_AGE';
export const PENDING = 'PENDING';