import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as RequestState from 'marketplace-ui-common/requestStates/RequestState';
/* @ts-expect-error module is untyped */
import { maybeEarlyRequest } from '../utils/earlyRequests';
/* @ts-expect-error module is untyped */
import { fetchIntegrations } from '../clients/ecosystemClient';
/* @ts-expect-error module is untyped */
import localeComparator from 'I18n/utils/localeComparator';
import I18n from 'I18n';
const integrationsInitialState = {
  data: [],
  requestState: RequestState.UNINITIALIZED
};
export const fetchIntegrationsThunk = createAsyncThunk('ecosystem/fetchIntegrations', () => maybeEarlyRequest('integrations', fetchIntegrations));
const integrationsSlice = createSlice({
  name: 'integrations',
  initialState: integrationsInitialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchIntegrationsThunk.pending, state => {
      state.requestState = RequestState.PENDING;
    });
    builder.addCase(fetchIntegrationsThunk.fulfilled, (state, action) => {
      const alphabetizedList = action.payload.offerings.sort((optA, optB) => localeComparator(optA.listingName, optB.listingName));
      const integrationsWithDisplayNames = alphabetizedList.map((integration, index) => {
        let displayName;
        const {
          listingName,
          companyName
        } = integration;
        const previousIntegration = alphabetizedList[index - 1] || {};
        const nextIntegration = alphabetizedList[index + 1] || {};
        if (listingName === previousIntegration.listingName || listingName === nextIntegration.listingName) {
          displayName = I18n.text('ecosystem-marketplace-solutions-ui.storefront.filters.integration.displayName', {
            listingName,
            companyName
          });
        } else {
          displayName = listingName;
        }
        return Object.assign({}, integration, {
          displayName
        });
      });
      state.requestState = RequestState.SUCCEEDED;
      state.data = integrationsWithDisplayNames;
    });
    builder.addCase(fetchIntegrationsThunk.rejected, state => {
      state.requestState = RequestState.FAILED;
    });
  }
});
export const integrationsReducer = integrationsSlice.reducer;
export const getIntegrations = state => state.integrations.data;
export const getIntegrationsKeyMirror = state => state.integrations.data.reduce((obj, integration) => {
  obj[integration.offeringId] = integration.displayName;
  return obj;
}, {});
export const getIntegrationsRequestState = state => state.integrations.requestState;