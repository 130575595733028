import * as Industries from './Industry';
import * as CompanySizes from './CompanySize';

// Display
export const MINIMUM_REVIEWS_FOR_DISPLAY = 3;

// Filter options
export const ALL = 'ALL';
export const FIVE_STARS = 'FIVE_STARS';
export const FOUR_STARS = 'FOUR_STARS';
export const THREE_STARS = 'THREE_STARS';
export const TWO_STARS = 'TWO_STARS';
export const ONE_STAR = 'ONE_STAR';

// Sort options
export const NEWEST = 'NEWEST';
export const OLDEST = 'OLDEST';
export const HIGHEST = 'HIGHEST_OVERALL_RATING';
export const LOWEST = 'LOWEST_OVERALL_RATING';
export const MOST_HELPFUL = 'MOST_HELPFUL';
export const RELEVANCE = 'RELEVANCE';

// Pagination
export const REVIEWS_PER_PAGE = 10;

// Text keys for local state
export const FILTER_KEY = 'filter'; // overall rating filter
export const INDUSTRY_KEY = 'industry'; // industry filter
export const EMPLOYEES_KEY = 'employees'; // company size filter
export const SERVICE_KEY = 'service'; // service filter
export const SORT_KEY = 'sort';
export const PAGE_KEY = 'page';
export const SEARCH_KEY = 'search';
export const FILTER = {
  ALL,
  FIVE_STARS,
  FOUR_STARS,
  THREE_STARS,
  TWO_STARS,
  ONE_STAR
};
export const INDUSTRY = Industries;
export const EMPLOYEES = CompanySizes;
export const SORT = {
  RELEVANCE,
  NEWEST,
  OLDEST,
  HIGHEST,
  LOWEST,
  MOST_HELPFUL
};
export const STATE_TO_QUERY = {
  // FILTER
  ALL: {},
  FIVE_STARS: {
    overallRating: 5
  },
  FOUR_STARS: {
    overallRating: 4
  },
  THREE_STARS: {
    overallRating: 3
  },
  TWO_STARS: {
    overallRating: 2
  },
  ONE_STAR: {
    overallRating: 1
  },
  // SORT
  NEWEST: {
    sortFields: [NEWEST]
  },
  OLDEST: {
    sortFields: [OLDEST]
  },
  HIGHEST: {
    sortFields: [HIGHEST]
  },
  LOWEST: {
    sortFields: [LOWEST]
  },
  [HIGHEST]: {
    sortFields: [HIGHEST]
  },
  [LOWEST]: {
    sortFields: [LOWEST]
  },
  MOST_HELPFUL: {
    sortFields: [MOST_HELPFUL]
  },
  [RELEVANCE]: {
    sortFields: [RELEVANCE]
  }
};
export const STAR_NUM_TO_STATE = new Map([['1', FILTER.ONE_STAR], ['2', FILTER.TWO_STARS], ['3', FILTER.THREE_STARS], ['4', FILTER.FOUR_STARS], ['5', FILTER.FIVE_STARS]]);
export const MINIMUM_SEARCH_LENGTH = 3;