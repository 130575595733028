'use es6';

import { createSelector } from 'reselect';
import { makePermissionComponent } from 'ecosystem-marketplace-core/components/permissions';
import * as AppContext from '../constants/AppContext';
import { isProd } from '../utils/env';
export const getAppContext = state => state.app.appContext;
const coreTypeMap = {
  [AppContext.IN_APP]: 'app',
  [AppContext.PUBLIC]: 'public'
};
export function getAppContextCoreType(state) {
  return coreTypeMap[state.app.appContext];
}
export const getTrackingParams = state => state.app.trackingParams;
export const getIsPublicApp = createSelector([getAppContext], appContext => appContext === AppContext.PUBLIC);
export const PublicAppComponent = makePermissionComponent(getIsPublicApp);
export const getCanonicalAppContext = createSelector([getAppContext, getIsPublicApp], (appContext, isPublicApp) => {
  if (isPublicApp && isProd()) {
    return AppContext.PUBLIC;
  }
  return appContext;
});