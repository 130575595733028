import produce from 'immer';
import { clear, count, every, flattenN, forEach, get, has, map, reduce, set, some, sortBy
// @ts-expect-error there's no type definition for this file, likely due to its "internal" subdirectory
} from 'transmute/internal/TransmuteCollection';
import { BaseImmutableObject } from 'immutable-less/BaseImmutableObject';
export function enableTransmute() {
  get.implement(Map, (key, subject) => {
    return subject.get(key);
  });
  set.implement(Map, (key, value, subject) => {
    return produce(subject, draft => {
      draft.set(key, value);
    });
  });
  clear.implementInherited(BaseImmutableObject, subject => {
    return subject.clear();
  });
  count.implementInherited(BaseImmutableObject, subject => {
    return Object.keys(subject).length;
  });
  every.implementInherited(BaseImmutableObject, (predicate, subject) => {
    return subject.every(predicate);
  });
  flattenN.implementInherited(BaseImmutableObject, (depth, subject) => {
    return subject.flatten(depth);
  });
  forEach.implementInherited(BaseImmutableObject, (predicate, subject) => {
    return subject.forEach(predicate);
  });
  has.implementInherited(BaseImmutableObject, (key, subject) => {
    return Object.hasOwnProperty.call(subject, key);
  });
  map.implementInherited(BaseImmutableObject, (mapper, subject) => {
    return subject.map(mapper);
  });
  reduce.implementInherited(BaseImmutableObject, (initialReduction, reducer, subject) => {
    return subject.reduce(reducer, initialReduction);
  });
  set.implementInherited(BaseImmutableObject, (key, value, subject) => {
    return subject.set(key, value);
  });
  some.implementInherited(BaseImmutableObject, (predicate, subject) => {
    return subject.some(predicate);
  });
  sortBy.implementInherited(BaseImmutableObject, (getSortValue, subject) => {
    return subject.sortBy(getSortValue);
  });
}