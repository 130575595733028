/* hs-eslint ignored failing-rules */
/* eslint-disable no-prototype-builtins */

'use es6';

let uiUpdated = false;
export default (({
  getState
}) => next => action => {
  // Detect external navigation, i.e. via the browser's back button
  // Prevent duplicate dispatches when the URL changes via the UI.
  if (action.type === 'storefront/filters/updateFromLocation') {
    if (uiUpdated) {
      uiUpdated = false;
      return null;
    }
  } else if (action.type === 'storefront/filters/update' || action.type === 'storefront/filters/clearFilters') {
    uiUpdated = true;
  }
  const state = getState();

  // pass catalogServices through to filters slice
  if (action.type === 'storefront/filters/updateFromLocation' || action.type === 'storefront/filters/update') {
    const catalogServices = state.services.services;
    action.payload.catalogServices = catalogServices;
  }
  const result = next(action);
  return result;
});