/* hs-eslint ignored failing-rules */
/* eslint-disable no-prototype-builtins */

'use es6';

import Immutable from 'immutable';
import { ProfileListing } from 'marketplace-ui-solutions-core/records/ProfileListing';
import { marketplaceSearchProfiles } from '../clients/marketplaceSearchClient';
import * as ActionTypes from './ActionTypes';
export const fetchProfiles = (query = {
  limit: 45
}) => dispatch => {
  dispatch({
    type: ActionTypes.STOREFRONT_PROFILES_REQUESTED
  });
  const searchClient = marketplaceSearchProfiles;
  return searchClient(query).then(resp => {
    const profiles = Immutable.List(
    // TODO eventual multi search
    resp[0].items.map(item => new ProfileListing(item)));
    dispatch({
      type: ActionTypes.STOREFRONT_PROFILES_RECEIVED,
      payload: profiles,
      meta: {
        total: resp[0].total
      }
    });
    return resp;
  }).catch(error => {
    dispatch({
      type: ActionTypes.STOREFRONT_PROFILES_FAILED,
      payload: error
    });
  });
};