'use es6';

import { useEffect } from 'react';

/**
 * @deprecated moved to marketplace-ui-common/hooks
 */
export const useScrollToTop = pathname => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};