import { fetchAccreditations } from '../clients/credentialsClient';
import * as ActionTypes from './ActionTypes';
export const getAccreditations = () => dispatch => {
  dispatch({
    type: ActionTypes.ACCREDITATIONS_REQUESTED
  });
  return fetchAccreditations().then(resp => {
    dispatch({
      type: ActionTypes.ACCREDITATIONS_RECEIVED,
      payload: resp
    });
  }).catch(error => {
    dispatch({
      type: ActionTypes.ACCREDITATIONS_FAILED,
      payload: error
    });
  });
};