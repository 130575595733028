'use es6';

import { INITIALIZE_AUTH } from '../actions/ActionTypes';
const initialState = {
  gates: [],
  portal: {},
  user: {}
};
const auth = (state = initialState, action) => {
  switch (action.type) {
    case INITIALIZE_AUTH:
      return {
        user: action.user,
        gates: action.gates,
        portal: action.portal
      };
    default:
      return state;
  }
};
export default auth;