'use es6';

import { combineReducers } from 'redux';
import Immutable from 'immutable';
import * as RequestState from 'marketplace-ui-common/requestStates/RequestState';
import * as ActionTypes from '../actions/ActionTypes';
const EMPTY_MAP = Immutable.Map();
const requestState = (state = RequestState.UNINITIALIZED, {
  type
}) => {
  switch (type) {
    case ActionTypes.ACADEMY_CERTIFICATIONS_REQUESTED:
      return RequestState.PENDING;
    case ActionTypes.ACADEMY_CERTIFICATIONS_RECEIVED:
      return RequestState.SUCCEEDED;
    case ActionTypes.ACADEMY_CERTIFICATIONS_FAILED:
      return RequestState.FAILED;
    default:
      return state;
  }
};
const data = (state = EMPTY_MAP, {
  type,
  payload
}) => {
  switch (type) {
    case ActionTypes.ACADEMY_CERTIFICATIONS_RECEIVED:
      return payload.results;
    default:
      return state;
  }
};
export default combineReducers({
  requestState,
  data
});