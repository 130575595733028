'use es6';

import * as FilterKey from './search/FilterKey';

// These should represent both the keys on the Profile record
// and the keys for the ecosystem-marketplace-solutions-ui.profile.general translations
export const INDUSTRIES = 'industries';
export const BUDGET = 'budget';
export const LANGUAGES = 'languages';
export const REGIONS = 'regions';
export const CERTIFICATIONS = 'certifications';
export const CREDENTIALS = 'credentials';
export const AWARDS = 'awards';
export const SERVICES = 'catalogServices';
export const TO_FILTER_VALUE_MAP = {
  [INDUSTRIES]: FilterKey.INDUSTRY,
  [BUDGET]: FilterKey.BUDGET,
  [LANGUAGES]: FilterKey.LANGUAGE,
  [REGIONS]: 'region',
  [CERTIFICATIONS]: FilterKey.CERTIFICATION,
  [AWARDS]: 'award',
  [SERVICES]: FilterKey.SERVICE
};
export default [INDUSTRIES, BUDGET, LANGUAGES, REGIONS, CERTIFICATIONS, AWARDS];