import I18n from 'I18n';
import formatName from 'I18n/utils/formatName';
export default function getDisplayedReviewerName({
  firstName,
  lastName,
  legacyFullName
}) {
  if (firstName && lastName) {
    return formatName({
      firstName,
      lastName
    });
  }
  if (legacyFullName) {
    return legacyFullName;
  }
  return I18n.text('marketplace-ui-reviews.defaultName');
}