import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["axes"],
  _excluded2 = ["axes"];
import produce from 'immer';
import { createImmutableObjectClass } from 'immutable-less/ImmutableObject';
import * as ReviewTypes from '../constants/ReviewType';
import { AppQualityRatingsOrder, RatingAxes } from '../constants/RatingAxes';
import { STAR_NUM_TO_STATE } from '../constants/ReviewsListQueries';
const reviewStatsDefaults = {
  count: null,
  reviewType: ReviewTypes.APP,
  axes: {}
};
export default class ReviewStats extends createImmutableObjectClass(reviewStatsDefaults, {
  fromJSON: _ref => {
    let {
        axes
      } = _ref,
      rest = _objectWithoutPropertiesLoose(_ref, _excluded);
    return Object.assign({
      axes: new Map(Object.entries(axes))
    }, rest);
  },
  toJSON: _ref2 => {
    let {
        axes
      } = _ref2,
      rest = _objectWithoutPropertiesLoose(_ref2, _excluded2);
    return Object.assign({
      axes: Object.fromEntries(axes)
    }, rest);
  }
}) {
  getOverallPercentages() {
    const overall = this.axes.get(RatingAxes.OVERALL);
    if (!overall || !overall.distribution) {
      return null;
    }
    const sum = Object.keys(overall.distribution).reduce((acc, key) => {
      acc += overall.distribution[parseInt(key, 10)];
      return acc;
    }, 0);
    let acc = 0; // Total percentages so far; should eventually be 100
    let difference = 0; // Ensure that overflow from rounding gets accounted for in the next calculation
    return Object.entries(overall.distribution).reduce((result, [key, value]) => {
      if (key === '5') {
        // Ensures that percentages add up to 100
        result[key] = 100 - acc;
        return result;
      }
      const percentage = value * 100 / sum + difference;
      const roundedPercentage = Math.round(percentage);
      difference = percentage - roundedPercentage;
      acc += roundedPercentage;
      result[key] = roundedPercentage;
      return result;
    }, {});
  }
  getQualityRatings() {
    const axes = this.axes;
    const orderedRatings = AppQualityRatingsOrder.reduce((result, key) => {
      if (!axes.has(key)) {
        return result;
      }
      result.set(key, axes.get(key).average);
      return result;
    }, new Map());
    axes.forEach((value, key) => {
      if (key !== RatingAxes.OVERALL && !orderedRatings.has(key)) {
        orderedRatings.set(key, value.average);
      }
    });
    return orderedRatings;
  }
  hasQualityRatings() {
    return Array.from(this.getQualityRatings().values()).some(val => val);
  }
  getAxisAverage(axis) {
    return this.axes.get(axis).average;
  }
  getValidRatingFilters() {
    const overallDistribution = this.axes.get(RatingAxes.OVERALL) && this.axes.get(RatingAxes.OVERALL).distribution;
    return Object.entries(overallDistribution).reduce((result, [key, val]) => {
      if (val) {
        result.set(STAR_NUM_TO_STATE.get(key), val);
      }
      return result;
    }, new Map());
  }
  addNewStats(ratings) {
    return produce(this, reviewStatsDraft => {
      reviewStatsDraft.set('count', reviewStatsDraft.get('count') + 1);
      ratings.forEach((value, key) => {
        reviewStatsDraft.axes.get(key).average = this.findAverage(reviewStatsDraft.axes.get(key).average, reviewStatsDraft.axes.get(key).count, value);
        reviewStatsDraft.axes.get(key).count += 1;
        reviewStatsDraft.axes.get(key).distribution[value] += 1;
      });
    });
  }
  findAverage(currentAverage, count, newRating) {
    count++;
    const weightedOld = currentAverage * ((count - 1) / count);
    const weightedNew = newRating * (1 / count);
    return weightedOld + weightedNew;
  }
}