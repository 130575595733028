import { apiClientProvider } from '../utils/apiClient';
let apiBase = 'marketplace/profiles/v1';
export const setProfilesApiBase = base => {
  apiBase = base;
};
export const fetchProfileListing = apiClientProvider((apiClient, slug) => apiClient.get(`${apiBase}/profiles/details`, {
  query: {
    slug
  }
}));
export const fetchProfileListingPreview = apiClientProvider(apiClient => apiClient.get(`${apiBase}/profiles/details/preview`));
export const fetchUserHasProfile = apiClientProvider(apiClient => {
  return apiClient.get(`${apiBase}/profiles/existence`);
});