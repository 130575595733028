import { useCallback, useState } from 'react';
import { getOffsetTop } from '../utils/scroll';
import { requestIdleCallback } from '../utils/requestIdleCallback';
export function useHeaderTabs(tabIds, scrollOffset) {
  const [activeTab, setActiveTab] = useState(tabIds[0]);
  const [tabClicked, setTabClicked] = useState(false);
  const calculateActiveTab = useCallback(() => {
    const cancelCalculateCallback = requestIdleCallback(() => {
      if (!tabClicked) {
        const scrollPosition = document.body.classList.contains('sticky-global-toolbar') ? document.getElementsByClassName('page')[0].scrollTop + scrollOffset : window.scrollY + scrollOffset;
        for (let idIndex = tabIds.length - 1; idIndex > 0; idIndex--) {
          const tabId = tabIds[idIndex];
          const tabElement = document.querySelector(`#${tabId}`);
          if (tabElement && scrollPosition > getOffsetTop(tabElement)) {
            setActiveTab(tabId);
            return;
          }
        }
        setActiveTab(tabIds[0]);
      }
    }, 200);
    return () => cancelCalculateCallback();
  }, [scrollOffset, tabClicked, tabIds]);
  const scrollToTabSection = useCallback(tabId => {
    const cancelScrollCallback = requestIdleCallback(() => {
      const tab = document.querySelector(`#${tabId}`);
      if (tab) {
        tab.scrollIntoView({
          behavior: 'smooth'
        });
      }
    });
    setActiveTab(tabId);
    setTabClicked(true);
    setTimeout(() => {
      setTabClicked(false);
    }, 1000);
    return () => cancelScrollCallback();
  }, [setActiveTab, setTabClicked]);
  return {
    calculateActiveTab,
    scrollToTabSection,
    activeTab
  };
}