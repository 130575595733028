import * as SortField from './SortField';
export const SortFieldToApiRequestObject = {
  [SortField.PARTNER_TIER_DESC]: {
    sortField: 'PARTNER_TIER',
    sortOrder: 'DESC'
  },
  [SortField.NEWEST]: {
    sortField: 'CREATED_AT',
    sortOrder: 'DESC'
  },
  [SortField.NAME_A_Z]: {
    sortField: 'NAME',
    sortOrder: 'ASC'
  },
  [SortField.NAME_Z_A]: {
    sortField: 'NAME',
    sortOrder: 'DESC'
  },
  [SortField.RELEVANCE_DESC]: {
    sortField: 'RELEVANCE',
    sortOrder: 'DESC'
  },
  [SortField.PARTNER_TYPE_ASC]: {
    sortField: 'PARTNER_TYPE',
    sortOrder: 'ASC'
  },
  [SortField.REVIEW_COUNT_DESC]: {
    sortField: 'REVIEW_COUNT',
    sortOrder: 'DESC'
  },
  [SortField.OLDEST]: {
    sortField: 'CREATED_AT',
    sortOrder: 'ASC'
  }
};