import { registerMutation } from 'data-fetching-client';
import { ecosystemAppClient } from 'marketplace-ui-common/clients/ecosystemClient';
export const SUBMIT_REVIEW = registerMutation({
  fieldName: 'submitReviewResponse',
  args: ['review'],
  fetcher({
    review
  }) {
    return ecosystemAppClient.post('/reviews', {
      data: review
    });
  }
});