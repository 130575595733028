export const APP_STARTED = 'APP_STARTED';
export const INITIALIZE_AUTH = 'INITIALIZE_AUTH';
export const APP_CONTEXT_SET = 'APP_CONTEXT_SET';
export const PARTNER_AWARDS_REQUESTED = 'PARTNER_AWARDS_REQUESTED';
export const PARTNER_AWARDS_RECEIVED = 'PARTNER_AWARDS_RECEIVED';
export const PARTNER_AWARDS_FAILED = 'PARTNER_AWARDS_FAILED';
export const ACCREDITATIONS_REQUESTED = 'ACCREDITATIONS_REQUESTED';
export const ACCREDITATIONS_RECEIVED = 'ACCREDITATIONS_RECEIVED';
export const ACCREDITATIONS_FAILED = 'ACCREDITATIONS_FAILED';
export const ACADEMY_CERTIFICATIONS_REQUESTED = 'ACADEMY_CERTIFICATIONS_REQUESTED';
export const ACADEMY_CERTIFICATIONS_RECEIVED = 'ACADEMY_CERTIFICATIONS_RECEIVED';
export const ACADEMY_CERTIFICATIONS_FAILED = 'ACADEMY_CERTIFICATIONS_FAILED';
export const STOREFRONT_PROFILES_REQUESTED = 'STOREFRONT_PROFILES_REQUESTED';
export const STOREFRONT_PROFILES_RECEIVED = 'STOREFRONT_PROFILES_RECEIVED';
export const STOREFRONT_PROFILES_FAILED = 'STOREFRONT_PROFILES_FAILED';
export const PROFILE_LISTING_REQUESTED = 'PROFILE_LISTING_REQUESTED';
export const PROFILE_LISTING_RECEIVED = 'PROFILE_LISTING_RECEIVED';
export const PROFILE_LISTING_FAILED = 'PROFILE_LISTING_FAILED';
export const CLEAR_PROFILE_LISTING = 'CLEAR_PROFILE_LISTING';
export const PROFILE_REVIEW_SUMMARY_REQUESTED = 'PROFILE_REVIEW_SUMMARY_REQUESTED';
export const PROFILE_REVIEW_SUMMARY_RECEIVED = 'PROFILE_REVIEW_SUMMARY_RECEIVED';
export const PROFILE_REVIEW_SUMMARY_FAILED = 'PROFILE_REVIEW_SUMMARY_FAILED';
export const PROFILE_REVIEW_FILTERS_UPDATED = 'PROFILE_REVIEW_FILTERS_UPDATED';
export const PROFILE_REVIEW_FILTERS_RESET = 'PROFILE_REVIEW_FILTERS_RESET';