export const getDataFetchingClientRequestState = (error, loading, hasData) => {
  if (loading) {
    return 'PENDING';
  }
  if (error) {
    return 'FAILED';
  }
  if (hasData !== undefined) {
    return hasData ? 'SUCCEEDED' : 'UNINITIALIZED';
  }
  return 'SUCCEEDED';
};