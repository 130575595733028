'use es6';

import I18n from 'I18n';
export default (({
  numerator,
  denominator
}) => {
  return I18n.text('i18n.numberRepresentation.fraction', {
    numerator,
    denominator
  });
});