'use es6';

import { useRef, useEffect } from 'react';
/**
 * @deprecated moved to marketplace-ui-common/hooks
 */
export const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};