import { rawExecuteSchema } from './core';
export const string = {
  validate(value) {
    return typeof value === 'string';
  },
  getDescription() {
    return 'string';
  }
};
export const number = {
  validate(value) {
    return typeof value === 'number' && !Number.isNaN(value);
  },
  getDescription() {
    return 'number';
  }
};
export const nan = {
  validate(value) {
    return Number.isNaN(value);
  },
  getDescription() {
    return 'NaN';
  }
};
export const any = {
  validate(value) {
    return true;
  },
  getDescription() {
    return 'any';
  }
};
export const unknown = {
  validate(value) {
    return true;
  },
  getDescription() {
    return 'unknown';
  }
};
export const boolean = {
  validate(value) {
    return typeof value === 'boolean';
  },
  getDescription() {
    return 'boolean';
  }
};
export const bigint = {
  validate(value) {
    return typeof value === 'bigint';
  },
  getDescription() {
    return 'bigint';
  }
};
export function literal(value) {
  let description;
  return {
    validate(_value) {
      return _value === value;
    },
    getDescription() {
      if (!description) {
        description = typeof value === 'string' ? `'${value}'` : `${value}`;
      }
      return description;
    }
  };
}
export function optional(schema) {
  let description;
  return {
    validate(value, validateChild) {
      if (value === undefined || value === null) return true;
      validateChild(schema, value, undefined);
      return true;
    },
    getDescription() {
      if (!description) {
        description = `Maybe<${schema.getDescription()}>`;
      }
      return description;
    }
  };
}
export function union(...schemas) {
  let description;
  return {
    validate(value, __, ctx) {
      const childCtx = {
        quick: ctx.quick,
        schemaViolations: []
      };
      for (let i = 0; i < schemas.length; i++) {
        if (rawExecuteSchema(childCtx, schemas[i], value).type === 'pass') {
          return true;
        }
      }
      Array.prototype.push.apply(ctx.schemaViolations, childCtx.schemaViolations);
      return false;
    },
    getDescription() {
      if (description) {
        return description;
      } else if (schemas.length === 1) {
        description = schemas[0].getDescription();
      } else if (schemas.length === 0) {
        description = 'never';
      } else {
        const descriptionLines = [''];
        for (const schema of schemas) {
          const [first, ...rest] = schema.getDescription().split('\n');
          descriptionLines.push(`  | ${first}`, ...rest.map(line => `    ${line}`));
        }
        descriptionLines.push('');
        description = descriptionLines.map(line => line.trimEnd()).join('\n');
      }
      return description;
    }
  };
}
export function intersection(...schemas) {
  let description;
  return {
    validate(value, __, ctx) {
      for (let i = 0; i < schemas.length; i++) {
        if (rawExecuteSchema(ctx, schemas[i], value).type === 'fail') {
          return false;
        }
      }
      return true;
    },
    getDescription() {
      if (description) {
        return description;
      } else if (schemas.length === 1) {
        description = schemas[0].getDescription();
      } else if (schemas.length === 0) {
        description = 'unknown';
      } else {
        const descriptionLines = [''];
        for (const schema of schemas) {
          const [first, ...rest] = schema.getDescription().split('\n');
          descriptionLines.push(`  & ${first}`, ...rest.map(line => `    ${line}`));
        }
        descriptionLines.push('');
        description = descriptionLines.map(line => line.trimEnd()).join('\n');
      }
      return description;
    }
  };
}
export function tuple(...descriptorTuple) {
  let description;
  return {
    validate(value, validateChild) {
      if (!Array.isArray(value) || value.length !== descriptorTuple.length) {
        return false;
      }
      for (let i = 0; i < descriptorTuple.length; i++) {
        validateChild(descriptorTuple[i], value[i], i);
      }
      return true;
    },
    getDescription() {
      if (description) {
        return description;
      } else if (descriptorTuple.length === 0) {
        description = '[]';
      } else {
        const descriptionLines = ['['];
        for (const schema of descriptorTuple) {
          descriptionLines.push(...`${schema.getDescription()},`.split('\n').map(line => `  ${line}`.trimEnd()));
        }
        descriptionLines.push(']');
        description = descriptionLines.join('\n');
      }
      return description;
    }
  };
}
export function array(schema) {
  let description;
  return {
    validate(value, validateChild) {
      if (!Array.isArray(value)) {
        return false;
      }
      for (let i = 0; i < value.length; i++) {
        validateChild(schema, value[i], i);
      }
      return true;
    },
    getDescription() {
      if (!description) {
        description = `Array<${schema.getDescription()}>`;
      }
      return description;
    }
  };
}
export function object(descriptorObj) {
  const keys = Object.keys(descriptorObj);
  let description;
  return {
    validate(value, validateChild) {
      if (typeof value !== 'object' || value === null || Array.isArray(value)) {
        return false;
      }
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        validateChild(descriptorObj[key], value[key], key);
      }
      return true;
    },
    getDescription() {
      if (description) {
        return description;
      } else if (keys.length === 0) {
        description = '{}';
      } else {
        const descriptionLines = ['{'];
        for (const key of keys) {
          const [first, ...rest] = descriptorObj[key].getDescription().split('\n');
          descriptionLines.push(`  ${key}: ${first}`, ...rest.map(line => `  ${line}`));
          descriptionLines.push(`${descriptionLines.pop()},`);
        }
        descriptionLines.push('}');
        description = descriptionLines.map(line => line.trimEnd()).join('\n');
      }
      return description;
    }
  };
}
export function record(keysSchema, valuesSchema) {
  let description;
  return {
    validate(value, validateChild) {
      if (typeof value !== 'object' || value === null || Array.isArray(value)) {
        return false;
      }
      const keys = Object.keys(value);
      for (let i = 0; i < keys.length; i++) {
        validateChild(valuesSchema, value[keys[i]], keys[i]);
        if (keysSchema) {
          validateChild(keysSchema, keys[i], keys[i]);
        }
      }
      return true;
    },
    getDescription() {
      if (!description) {
        description = `Record<${keysSchema ? keysSchema.getDescription() : 'string'}, ${valuesSchema.getDescription()}>`;
      }
      return description;
    }
  };
}
export function custom(description, validator) {
  return {
    validate: validator,
    getDescription() {
      return description;
    }
  };
}
let referenceId = 0;
export function multiRecursive(...builders) {
  const refs = builders.map(() => ({}));
  for (let i = 0; i < builders.length; i++) {
    const id = ++referenceId;
    const ref = refs[i];
    const {
      getDescription,
      validate
    } = builders[i](refs);
    if (!getDescription || !validate) {
      throw new Error('Detected strong recursion');
    }
    ref.validate = validate;
    let describing = false;
    ref.getDescription = () => {
      if (describing) return `${id}#__self_reference__`;
      try {
        describing = true;
        const result = `${id}#(${getDescription()})`;
        return result;
      } finally {
        describing = false;
      }
    };
  }
  return refs;
}
export function recursive(builder) {
  const [result] = multiRecursive(([self]) => builder(self));
  return result;
}