import { createStack } from 'hub-http';
import promiseClient from 'hub-http/adapters/promiseClient';
import * as coreMiddlewares from 'hub-http/middlewares/core';
import appAuthStack from 'hub-http/stacks/hubapi';
import noAuthStack from 'hub-http/stacks/noAuthWithCredentialsHubapi';
import { privateHubSpotterStack } from 'hubspotter-http/stacks/hubSpotter';
export const ecosystemAppClient = promiseClient(createStack(coreMiddlewares.base('ecosystem/v1/'), appAuthStack));
export const ecosystemPublicClient = promiseClient(createStack(coreMiddlewares.base('ecosystem/public/v1/'), noAuthStack));
export const ecosystemAdminClient = promiseClient(createStack(coreMiddlewares.base('ecosystem/admin/v1/'), privateHubSpotterStack));
export function getEcosystemClient(appContext) {
  switch (appContext) {
    case 'public':
      return ecosystemPublicClient;
    default:
      return ecosystemAppClient;
  }
}
export function getCompatibility({
  appContext = 'app',
  offeringType
}) {
  const client = getEcosystemClient(appContext);
  let path = 'themes';
  switch (offeringType) {
    case 'APP':
      path = 'ops-hub';
      break;
    case 'MODULE':
      path = 'modules';
      break;
    case 'THEME':
      path = 'themes';
      break;
    default:
  }
  return client.get(`compatibility/${path}`);
}