'use es6';

import enviro from 'enviro';
const KEY_SEPARATOR = '::';
const SERVICE_NAME = 'ecosystem-marketplace-solutions';
const GATE_PREFIX = 'gate';
export const debug = key => {
  return enviro.debug(`${SERVICE_NAME}${key ? `${KEY_SEPARATOR}${key}` : ''}`) === 'true';
};
export const setDebugKey = (key, value = true) => {
  enviro.setDebug(`${SERVICE_NAME}${KEY_SEPARATOR}${key}`, value);
};
const getGateDebugKey = gate => `${GATE_PREFIX}${KEY_SEPARATOR}${gate}`;
export const debugGate = gate => debug(getGateDebugKey(gate));
export const setDebugGate = (gate, ...args) => setDebugKey(getGateDebugKey(gate), ...args);
export const isQa = () => {
  return enviro.isQa(SERVICE_NAME);
};
export const isProd = () => {
  return enviro.isProd(SERVICE_NAME);
};