import { registerMutation } from 'data-fetching-client';
import { ecosystemAppClient } from 'marketplace-ui-common/clients/ecosystemClient';
export const SET_REVIEW_HELPFUL = registerMutation({
  fieldName: 'setReviewHelpfulResponse',
  args: ['reviewId', 'isHelpful'],
  fetcher({
    reviewId,
    isHelpful
  }) {
    return ecosystemAppClient.put(`/reviews/${reviewId}/helpful`, {
      data: isHelpful
    });
  }
});