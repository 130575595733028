'use es6';

// Taken from UIComponents
export const getMobilePanelTransitionProps = (onOpenStart, onOpenComplete, onCloseStart, onCloseComplete, align, animate) => ({
  onOpenStart,
  onOpenComplete,
  onCloseStart,
  onCloseComplete,
  align,
  duration: animate ? undefined : 0
});