'use es6';

import * as Country from 'marketplace-ui-common/constants/Country';
import * as USState from 'marketplace-ui-solutions-core/constants/USState';
import * as PartnerTier from 'marketplace-ui-solutions-core/constants/PartnerTier';
import * as SortField from './SortField';
import { SortFieldToApiRequestObject } from './Sorts';
export const DEFAULT_LIMIT = 90;
const buildQuery = (country, localities, usState) => {
  return {
    countriesFilter: {
      clause: 'OR',
      values: [country]
    },
    usStatesFilter: usState ? {
      clause: 'OR',
      values: [usState]
    } : undefined,
    localities: {
      clause: 'OR',
      values: localities
    },
    sorts: [SortFieldToApiRequestObject[SortField.PARTNER_TIER_DESC], SortFieldToApiRequestObject[SortField.PARTNER_TYPE_ASC], SortFieldToApiRequestObject[SortField.RELEVANCE_DESC], SortFieldToApiRequestObject[SortField.REVIEW_COUNT_DESC], SortFieldToApiRequestObject[SortField.OLDEST]],
    partnerTiers: {
      clause: 'OR',
      values: [PartnerTier.ELITE, PartnerTier.DIAMOND, PartnerTier.PLATINUM, PartnerTier.GOLD]
    },
    offset: 0,
    limit: DEFAULT_LIMIT
  };
};
export const AU_MELBOURNE = buildQuery(Country.AU, ['Melbourne']);
export const AU_SYDNEY = buildQuery(Country.AU, ['Sydney']);
export const GB_LONDON = buildQuery(Country.GB, ['London', 'Horsham']);
export const GB_MANCHESTER = buildQuery(Country.GB, ['Manchester', 'Clitheroe', 'Macclesfield', 'Cheshire', 'Liverpool', 'Chester']);
export const SE_STOCKHOLM = buildQuery(Country.SE, ['Stockholm']);
export const US_BOSTON = buildQuery(Country.US, ['Boston', 'Framingham', 'Burlington', 'Cambridge'], USState.MA);
export const US_CHICAGO = buildQuery(Country.US, ['Chicago'], USState.IL);
export const US_CLEVELAND = buildQuery(Country.US, ['Cleveland', 'Avon', 'Solon', 'Northfield'], USState.OH);
export const US_DALLAS_FORT_WORTH = buildQuery(Country.US, ['Dallas', 'Fort Worth', 'Irving'], USState.TX);
export const US_DENVER = buildQuery(Country.US, ['Denver', 'Golden'], USState.CO);
export const US_HOUSTON = buildQuery(Country.US, ['Houston', 'The Woodlands'], USState.TX);
export const US_LOS_ANGELES = buildQuery(Country.US, ['Los Angeles', 'Irvine', 'Newport Beach', 'Orange County', 'Riverside', 'Santa Monica'], USState.CA);
export const US_NEW_YORK_CITY = buildQuery(Country.US, ['New York', 'Ossining', 'Manhattan', 'Brooklyn', 'Queens', 'The Bronx', 'Staten Island', 'Long Island'], USState.NY);
export const US_PHILADELPHIA = buildQuery(Country.US, ['Philadelphia', 'Elizabethtown', 'Conshohocken'], USState.PA);
export const US_SAN_DIEGO = buildQuery(Country.US, ['San Diego', 'Carlsbad'], USState.CA);