import I18n from 'I18n';
import * as MarketplaceIndustries from 'marketplace-ui-common/constants/Industry';
import * as CompanySizes from '../constants/CompanySize';
import Review from '../records/Review';
import { OTHER } from 'marketplace-ui-common/constants/Industry';
import { PREFER_NOT_TO_SAY } from '../constants/CompanySize';
export const getIndustryOptions = (withOther = false) => {
  const industryEnums = withOther ? Object.values(MarketplaceIndustries) : Object.values(MarketplaceIndustries).filter(industry => industry !== OTHER);
  return industryEnums.map(industry => {
    return {
      text: I18n.text(`marketplace-ui-common.industry.${industry}.title`),
      value: industry
    };
  }).sort((a, b) => {
    return b.text < a.text ? 1 : -1;
  });
};
export const getEmployeesOptions = (withPreferNotToSay = false) => {
  const employeeSizeEnums = withPreferNotToSay ? Object.values(CompanySizes) : Object.values(CompanySizes).filter(value => value !== PREFER_NOT_TO_SAY);
  return employeeSizeEnums.map(range => {
    return {
      text: Review.toEmployeeString(range),
      value: range
    };
  });
};