export const AL = 'AL';
export const AK = 'AK';
export const AZ = 'AZ';
export const AR = 'AR';
export const CA = 'CA';
export const CO = 'CO';
export const CT = 'CT';
export const DE = 'DE';
export const DC = 'DC';
export const FL = 'FL';
export const GA = 'GA';
export const HI = 'HI';
export const ID = 'ID';
export const IL = 'IL';
export const IN = 'IN';
export const IA = 'IA';
export const KS = 'KS';
export const KY = 'KY';
export const LA = 'LA';
export const ME = 'ME';
export const MD = 'MD';
export const MA = 'MA';
export const MI = 'MI';
export const MN = 'MN';
export const MS = 'MS';
export const MO = 'MO';
export const MT = 'MT';
export const NE = 'NE';
export const NV = 'NV';
export const NH = 'NH';
export const NJ = 'NJ';
export const NM = 'NM';
export const NY = 'NY';
export const NC = 'NC';
export const ND = 'ND';
export const OH = 'OH';
export const OK = 'OK';
export const OR = 'OR';
export const PA = 'PA';
export const RI = 'RI';
export const SC = 'SC';
export const SD = 'SD';
export const TN = 'TN';
export const TX = 'TX';
export const UT = 'UT';
export const VT = 'VT';
export const VA = 'VA';
export const WA = 'WA';
export const WV = 'WV';
export const WI = 'WI';
export const WY = 'WY';