import * as t from 'ts-schema';
import { offeringTypeSchema } from '../../offerings/schemas';
export const calloutSchema = t.object({
  id: t.number,
  internalName: t.string,
  offeringType: offeringTypeSchema,
  publishedListing: t.object({
    buttonText: t.string,
    collectionId: t.union(t.number, t.literal(null)),
    description: t.string,
    externalUrl: t.union(t.string, t.literal(null)),
    gradientColor: t.union(t.literal('THUNDERDOME_TO_CALYPSO'), t.literal('CALYPSO_TO_OZ'), t.literal('SORBET_TO_MARIGOLD'), t.literal('NORMAN_TO_CANDY_APPLE'), t.literal('CANDY_APPLE_TO_LORAX'), t.literal('LORAX_TO_SORBET')),
    headline: t.string,
    illustration: t.string
  })
});
export const relevantCalloutIdsResponseSchema = t.object({
  calloutIds: t.array(t.number)
});
export const featuredCalloutsResponseSchema = t.array(calloutSchema);