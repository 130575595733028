export const getCategoryI18nKey = category => {
  return `marketplace-ui-common.groupTags.category.${category}`;
};
export const getHubSpotFeatureGroupI18nKey = key => {
  if (key === 'CMS') {
    return 'marketplace-ui-common.groupTags.hubspotFeatureGroups.CONTENT';
  }
  return `marketplace-ui-common.groupTags.hubspotFeatureGroups.${key}`;
};
export const getHubSpotFeatureI18nKey = key => {
  return `marketplace-ui-common.groupTags.hubspotFeature.${key}`;
};
export const getTierI18nKey = tier => {
  return `marketplace-ui-common.groupTags.tier.${tier}`;
};
export const getTierHubI18nKey = hub => {
  return `marketplace-ui-common.groupTags.tierHub.${hub}`;
};
export const getTierLevelI18nKey = level => {
  return `marketplace-ui-common.groupTags.tierLevel.${level}`;
};

// i.e. "the United States"
const thePrefixedCountries = ['US', 'GB', 'CZ', 'DO', 'FK', 'HM', 'MH', 'NL', 'MP', 'PH', 'SB', 'TC', 'AE', 'UM', 'VI', 'VG', 'EH', 'AX', 'AP', 'CF', 'CD'];
export const getCountrySentenceI18nKey = country => {
  if (thePrefixedCountries.includes(country)) {
    return `marketplace-ui-common.prefixedCountryCodes.${country}`;
  } else {
    return `SharedI18nStrings.countryCodes.${country}`;
  }
};