'use es6';

import { createSelector } from 'reselect';
// import { gate } from 'hub-http/gates';
import * as AppContext from '../constants/AppContext';
// import { debugGate } from '../utils/env';
import { getAppContext, getIsPublicApp } from './app';

// commenting this code to enable gating logic in the future
// const getGates = (state) => state.auth.gates;

// const getIsUngated = createSelector(
//   [getGates],
//   // eslint-disable-next-line
//   (gates) => (gateName) => gates.includes(gate(gateName)) || debugGate(gateName)
// );

const getUser = state => state.auth.user;
export const getIsCustomer = createSelector([getAppContext], appContext => appContext === AppContext.IN_APP);
export const getUserDisplayName = createSelector([getUser, getIsPublicApp], (user, isPublic) => isPublic ? null : `${user.first_name} ${user.last_name.slice(0, 1)}.`);
export const getUserFirstName = createSelector([getUser, getIsPublicApp], (user, isPublic) => isPublic ? null : user.first_name);
export const getUserLastName = createSelector([getUser, getIsPublicApp], (user, isPublic) => isPublic ? null : user.last_name);
export const getUserEmail = createSelector([getUser], user => user.email);
export const getUserId = createSelector([getUser], user => user.user_id);
export const getIsHubSpotter = createSelector([getUserEmail], email => email.endsWith('@hubspot.com'));