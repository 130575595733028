'use es6';

import * as FilterKey from '../constants/search/FilterKey';
import { OfficeLocationType } from '../constants/search/Filters';
import * as SortField from '../constants/search/SortField';
import { SortFieldToApiRequestObject } from '../constants/search/Sorts';

// Note: keep in sync with early requester
export const getSortOptionValue = sort => {
  switch (sort) {
    case SortField.PARTNER_TIER_DESC:
      return [SortFieldToApiRequestObject[SortField.PARTNER_TIER_DESC], SortFieldToApiRequestObject[SortField.PARTNER_TYPE_ASC], SortFieldToApiRequestObject[SortField.RELEVANCE_DESC], SortFieldToApiRequestObject[SortField.REVIEW_COUNT_DESC], SortFieldToApiRequestObject[SortField.OLDEST]];
    case SortField.RELEVANCE_DESC:
      return [SortFieldToApiRequestObject[SortField.RELEVANCE_DESC], SortFieldToApiRequestObject[SortField.PARTNER_TIER_DESC], SortFieldToApiRequestObject[SortField.PARTNER_TYPE_ASC]];
    default:
      return [SortFieldToApiRequestObject[sort]];
  }
};
export const getSearchValueFromFilterKey = (filterKey, value) => {
  switch (filterKey) {
    case FilterKey.COUNTRY:
      return value === 'ANY' ? undefined : {
        clause: 'OR',
        values: [value]
      };
    case FilterKey.SERVICE:
    case FilterKey.INDUSTRY:
    case FilterKey.BUDGET:
    case FilterKey.CERTIFICATION:
    case FilterKey.LANGUAGE:
    case FilterKey.TIER:
    case FilterKey.STATE:
    case FilterKey.CREDENTIAL:
    case FilterKey.INTEGRATION:
      return {
        clause: 'OR',
        values: value
      };
    case FilterKey.OFFICE_LOCATION:
      {
        if (value === OfficeLocationType.REMOTE) {
          return true;
        } else if (value === OfficeLocationType.PHYSICAL) {
          return false;
        } else {
          return undefined;
        }
      }
    case FilterKey.SORTS:
      return getSortOptionValue(value);
    default:
      return value;
  }
};

// determines if a user has any selected filters
export const getHasFilters = filterState => {
  return !!Object.keys(filterState).filter(filterKey => {
    return filterKey !== FilterKey.OFFSET && filterKey !== FilterKey.SORTS && filterState[filterKey].length;
  }).length;
};