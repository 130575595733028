import http from 'hub-http/clients/apiClient';
let apiClient;
export const getApiClient = () => apiClient || http;
export const setApiClient = _apiClient => {
  apiClient = _apiClient;
};
export const apiClientProvider = wrappedFunc => (...args) => {
  if (!apiClient) {
    console.warn('[apiClient] No apiClient defined. Defaulting to authed apiClient.');
  }
  return wrappedFunc(apiClient || http, ...args);
};