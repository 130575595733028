export const AD = 'andorra';
export const AE = 'united-arab-emirates';
export const AF = 'afghanistan';
export const AG = 'antigua-and-barbuda';
export const AI = 'anguilla';
export const AL = 'albania';
export const AM = 'armenia';
export const AN = 'netherlands-antilles';
export const AO = 'angola';
export const AP = 'asia-pacific-region';
export const AQ = 'antarctica';
export const AR = 'argentina';
export const AS = 'american-samoa';
export const AT = 'austria';
export const AU = 'australia';
export const AW = 'aruba';
export const AX = 'aland-islands';
export const AZ = 'azerbaijan';
export const BA = 'bosnia-and-herzegovina';
export const BB = 'barbados';
export const BD = 'bangladesh';
export const BE = 'belgium';
export const BF = 'burkina-faso';
export const BG = 'bulgaria';
export const BH = 'bahrain';
export const BI = 'burundi';
export const BJ = 'benin';
export const BL = 'saint-barthelemy';
export const BM = 'bermuda';
export const BN = 'brunei';
export const BO = 'bolivia';
export const BQ = 'caribbean-netherlands';
export const BR = 'brazil';
export const BS = 'bahamas';
export const BT = 'bhutan';
export const BV = 'bouvet-island';
export const BW = 'botswana';
export const BY = 'belarus';
export const BZ = 'belize';
export const CA = 'canada';
export const CC = 'cocos-keeling-islands';
export const CD = 'democratic-republic-of-the-congo';
export const CF = 'central-african-republic';
export const CG = 'congo';
export const CH = 'switzerland';
export const CI = 'cote-d-ivoire';
export const CK = 'cook-islands';
export const CL = 'chile';
export const CM = 'cameroon';
export const CN = 'china';
export const CO = 'colombia';
export const CR = 'costa-rica';
export const CU = 'cuba';
export const CV = 'cape-verde';
export const CW = 'curacao';
export const CX = 'christmas-island';
export const CY = 'cyprus';
export const CZ = 'czech-republic';
export const DE = 'germany';
export const DJ = 'djibouti';
export const DK = 'denmark';
export const DM = 'dominica';
export const DO = 'dominican-republic';
export const DZ = 'algeria';
export const EC = 'ecuador';
export const EE = 'estonia';
export const EG = 'egypt';
export const EH = 'western-sahara';
export const ER = 'eritrea';
export const ES = 'spain';
export const ET = 'ethiopia';
export const EU = 'europe';
export const FI = 'finland';
export const FJ = 'fiji';
export const FK = 'falkland-islands';
export const FM = 'micronesia';
export const FO = 'faroe-islands';
export const FR = 'france';
export const GA = 'gabon';
export const GB = 'united-kingdom';
export const GD = 'grenada';
export const GE = 'georgia';
export const GF = 'french-guiana';
export const GG = 'guernsey';
export const GH = 'ghana';
export const GI = 'gibraltar';
export const GL = 'greenland';
export const GM = 'gambia';
export const GN = 'guinea';
export const GP = 'guadeloupe';
export const GQ = 'equatorial-guinea';
export const GR = 'greece';
export const GS = 'south-georgia-and-the-south-sandwich-islands';
export const GT = 'guatemala';
export const GU = 'guam';
export const GW = 'guinea-bissau';
export const GY = 'guyana';
export const HK = 'hong-kong';
export const HM = 'heard-island-and-mcdonald-islands';
export const HN = 'honduras';
export const HR = 'croatia';
export const HT = 'haiti';
export const HU = 'hungary';
export const ID = 'indonesia';
export const IE = 'ireland';
export const IL = 'israel';
export const IM = 'isle-of-man';
export const IN = 'india';
export const IO = 'british-indian-ocean-territory';
export const IQ = 'iraq';
export const IR = 'iran';
export const IS = 'iceland';
export const IT = 'italy';
export const JE = 'jersey';
export const JM = 'jamaica';
export const JO = 'jordan';
export const JP = 'japan';
export const KE = 'kenya';
export const KG = 'kyrgyzstan';
export const KH = 'cambodia';
export const KI = 'kiribati';
export const KM = 'comoros';
export const KN = 'saint-kitts-and-nevis';
export const KP = 'north-korea';
export const KR = 'south-korea';
export const KW = 'kuwait';
export const KY = 'cayman-islands';
export const KZ = 'kazakhstan';
export const LA = 'laos';
export const LB = 'lebanon';
export const LC = 'saint-lucia';
export const LI = 'liechtenstein';
export const LK = 'sri-lanka';
export const LR = 'liberia';
export const LS = 'lesotho';
export const LT = 'lithuania';
export const LU = 'luxembourg';
export const LV = 'latvia';
export const LY = 'libya';
export const MA = 'morocco';
export const MC = 'monaco';
export const MD = 'moldova';
export const ME = 'montenegro';
export const MF = 'saint-martin';
export const MG = 'madagascar';
export const MH = 'marshall-islands';
export const MK = 'macedonia-fyrom';
export const ML = 'mali';
export const MM = 'myanmar-burma';
export const MN = 'mongolia';
export const MO = 'macau';
export const MP = 'northern-mariana-islands';
export const MQ = 'martinique';
export const MR = 'mauritania';
export const MS = 'montserrat';
export const MT = 'malta';
export const MU = 'mauritius';
export const MV = 'maldives';
export const MW = 'malawi';
export const MX = 'mexico';
export const MY = 'malaysia';
export const MZ = 'mozambique';
export const NA = 'namibia';
export const NC = 'new-caledonia';
export const NE = 'niger';
export const NF = 'norfolk-island';
export const NG = 'nigeria';
export const NI = 'nicaragua';
export const NL = 'netherlands';
export const NO = 'norway';
export const NP = 'nepal';
export const NR = 'nauru';
export const NU = 'niue';
export const NZ = 'new-zealand';
export const OM = 'oman';
export const PA = 'panama';
export const PE = 'peru';
export const PF = 'french-polynesia';
export const PG = 'papua-new-guinea';
export const PH = 'philippines';
export const PK = 'pakistan';
export const PL = 'poland';
export const PM = 'saint-pierre-and-miquelon';
export const PN = 'pitcairn-islands';
export const PR = 'puerto-rico';
export const PS = 'palestine';
export const PT = 'portugal';
export const PW = 'palau';
export const PY = 'paraguay';
export const QA = 'qatar';
export const RE = 'reunion';
export const RO = 'romania';
export const RS = 'serbia';
export const RU = 'russia';
export const RW = 'rwanda';
export const SA = 'saudi-arabia';
export const SB = 'solomon-islands';
export const SC = 'seychelles';
export const SD = 'sudan';
export const SE = 'sweden';
export const SG = 'singapore';
export const SH = 'saint-helena';
export const SI = 'slovenia';
export const SJ = 'svalbard-and-jan-mayen';
export const SK = 'slovakia';
export const SL = 'sierra-leone';
export const SM = 'san-marino';
export const SN = 'senegal';
export const SO = 'somalia';
export const SR = 'suriname';
export const SS = 'south-sudan';
export const ST = 'sao-tome-and-principe';
export const SV = 'el-salvador';
export const SX = 'sint-maarten';
export const SY = 'syria';
export const SZ = 'swaziland';
export const TC = 'turks-and-caicos-islands';
export const TD = 'chad';
export const TF = 'french-southern-and-antarctic-lands';
export const TG = 'togo';
export const TH = 'thailand';
export const TJ = 'tajikistan';
export const TK = 'tokelau';
export const TL = 'east-timor';
export const TM = 'turkmenistan';
export const TN = 'tunisia';
export const TO = 'tonga';
export const TR = 'turkey';
export const TT = 'trinidad-and-tobago';
export const TV = 'tuvalu';
export const TW = 'taiwan';
export const TZ = 'tanzania';
export const UA = 'ukraine';
export const UG = 'uganda';
export const UM = 'united-states-minor-outlying-islands';
export const US = 'united-states';
export const UY = 'uruguay';
export const UZ = 'uzbekistan';
export const VA = 'vatican-city';
export const VC = 'saint-vincent-and-the-grenadines';
export const VE = 'venezuela';
export const VG = 'british-virgin-islands';
export const VI = 'us-virgin-islands';
export const VN = 'vietnam';
export const VU = 'vanuatu';
export const WF = 'wallis-and-futuna';
export const WS = 'samoa';
export const YE = 'yemen';
export const YT = 'mayotte';
export const ZA = 'south-africa';
export const ZM = 'zambia';
export const ZW = 'zimbabwe';