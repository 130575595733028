import getLangLocale from 'I18n/utils/getLangLocale';
import { createStack } from 'hub-http';
import promiseClient from 'hub-http/adapters/promiseClient';
import * as coreMiddlewares from 'hub-http/middlewares/core';
import appAuthStack from 'hub-http/stacks/hubapi';
import noAuthStack from 'hub-http/stacks/noAuthWithCredentialsHubapi';
import { privateHubSpotterStack } from 'hubspotter-http/stacks/hubSpotter';
import { collectionIdsResponseSchema, collectionsResponseSchema } from 'marketplace-ui-client-types/marketplace-quality-customer-service-v1/schema';
import { adminCollectionsResponseSchema } from 'marketplace-ui-client-types/marketplace-quality-service-v1/schema';
import * as t from 'ts-schema';
import { featuredCalloutsResponseSchema, relevantCalloutIdsResponseSchema } from '../callouts/types/Callout';
import { collectionsResponseSchema as collectionsSchema } from '../collections/types/Collection';
import { getQuickFetch } from '../utils/earlyRequests';
export const qualityInternalClient = promiseClient(createStack(coreMiddlewares.base('marketplace/quality/v1'), privateHubSpotterStack));
export const qualityAppClient = promiseClient(createStack(coreMiddlewares.base('marketplace/quality/customer/v1'), appAuthStack));
export const qualityPublicClient = promiseClient(createStack(coreMiddlewares.base('marketplace/quality/customer/v1'), noAuthStack));
export function getQualityClient(appContext) {
  switch (appContext) {
    case 'public':
      return qualityPublicClient;
    case 'admin-preview':
      return qualityInternalClient;
    default:
      return qualityAppClient;
  }
}
const SELF_SERVE_OFFERING_TYPE_PATHS = {
  APP: 'apps',
  MODULE: 'modules',
  THEME: 'themes'
};
function getSelfServeApiPath(appContext, offeringType) {
  let path = `${appContext === 'public' ? '/public' : ''}/self-serve`;
  if (offeringType) {
    path += `/${SELF_SERVE_OFFERING_TYPE_PATHS[offeringType]}`;
  }
  return path;
}
export function getRelevantFeaturedCalloutIds({
  appContext,
  offeringType
}) {
  const client = getQualityClient(appContext);
  const path = `${getSelfServeApiPath(appContext)}/callouts/published/relevant/ids`;
  return client.get(path, {
    query: {
      offeringType
    }
  }).then(calloutIds => t.assert(relevantCalloutIdsResponseSchema, calloutIds));
}
export function getFeaturedCallouts({
  appContext,
  offeringType
}) {
  return getQuickFetch('self-serve-callouts').catch(() => {
    const client = getQualityClient(appContext);
    const publishedPath = appContext === 'admin-preview' ? '' : 'published';
    const path = `${getSelfServeApiPath(appContext)}/callouts/${publishedPath}`;
    return client.get(path, {
      query: {
        offeringType,
        locale: getLangLocale()
      }
    }).then(callouts => t.assert(featuredCalloutsResponseSchema, callouts));
  });
}
export function getRelevantCollectionIds({
  appContext,
  offeringType
}) {
  const client = getQualityClient(appContext);
  const path = `${getSelfServeApiPath(appContext, offeringType)}/collections/published/relevant/ids`;
  return client.get(path).then(relevantIds => t.assert(collectionIdsResponseSchema, relevantIds));
}
export function getCollections({
  appContext,
  offeringType
}) {
  const client = getQualityClient(appContext);
  const publishedPath = appContext === 'admin-preview' ? '' : 'published';
  const path = `${getSelfServeApiPath(appContext, offeringType)}/collections/${publishedPath}`;
  return client.get(path, {
    query: {
      locale: getLangLocale()
    }
  }).then(collections => t.assert(collectionsSchema, collections));
}
export function getPublishedCollections({
  appContext,
  offeringType
}) {
  const client = getQualityClient(appContext);
  const path = `${getSelfServeApiPath(appContext, offeringType)}/collections/published`;
  return client.get(path, {
    query: {
      locale: getLangLocale()
    }
  }).then(collections => t.assert(collectionsResponseSchema, collections));
}
export function getPreviewCollections({
  offeringType
}) {
  const client = getQualityClient('admin-preview');
  const path = `${getSelfServeApiPath('admin-preview', offeringType)}/collections`;
  return client.get(path, {
    query: {
      locale: getLangLocale()
    }
  }).then(collections => t.assert(adminCollectionsResponseSchema, collections));
}
export function getCollectionItems({
  appContext,
  offeringType,
  collectionId,
  language,
  length,
  offset
}) {
  const client = getQualityClient(appContext);
  const path = `${getSelfServeApiPath(appContext, offeringType)}/collections/${collectionId}/items`;
  return client.get(path, {
    query: {
      language,
      length,
      offset
    }
  });
}