const RECENT_ACTIVITY = 'RECENT_ACTIVITY';
const numberOfProfileViewsToTrack = 3;
export const getRecentActivity = () => {
  let recentActivity;
  try {
    recentActivity = localStorage.getItem(RECENT_ACTIVITY);
    return recentActivity ? JSON.parse(recentActivity) : null;
  } catch (error) {
    console.error(error);
    return null;
  }
};
export const setRecentActivity = activity => {
  const recentActivity = getRecentActivity();
  const stringifiedFilters = recentActivity && JSON.stringify(recentActivity.filters);
  const stringifiedQuestionnaireFilters = recentActivity && JSON.stringify(recentActivity.questionnaireFilters);
  const hasDifferences = JSON.stringify(activity.filters) !== stringifiedQuestionnaireFilters && (JSON.stringify(activity.filters) !== stringifiedFilters || JSON.stringify(activity.questionnaireFilters) !== stringifiedQuestionnaireFilters);
  if (hasDifferences || activity.type === 'profileView' || activity.type === 'questionnaire') {
    try {
      localStorage.setItem(RECENT_ACTIVITY, JSON.stringify(activity));
    } catch (e) {
      console.error(e);
    }
  }
};
export const clearRecentActivity = () => {
  try {
    localStorage.removeItem(RECENT_ACTIVITY);
  } catch (e) {
    console.error(e);
  }
};
export const addViewedProfile = profileName => {
  if (!profileName.length) {
    return;
  }
  const recentActivity = getRecentActivity();
  const viewedProfiles = recentActivity && recentActivity.viewedProfiles || [];
  if (viewedProfiles.indexOf(profileName) !== -1) {
    viewedProfiles.splice(viewedProfiles.indexOf(profileName), 1);
  }
  const updatedViewedProfiles = [profileName, ...viewedProfiles.slice(0, numberOfProfileViewsToTrack - 1)];
  setRecentActivity(Object.assign({}, recentActivity, {
    type: recentActivity && recentActivity.type === 'questionnaire' ? 'questionnaire' : 'profileView',
    viewedProfiles: updatedViewedProfiles
  }));
};