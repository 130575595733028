export const ReviewSteps = {
  RATE: 'RATE',
  SHARE: 'SHARE',
  ABOUT: 'ABOUT'
};
export const AppReviewSteps = {
  RATE: 'RATE',
  SHARE: 'SHARE',
  FEEDBACK: 'FEEDBACK',
  ABOUT: 'ABOUT'
};
export const ProfileReviewSteps = {
  RATE: 'RATE',
  ABOUT: 'ABOUT'
};