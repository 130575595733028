'use es6';

import PropTypes from 'prop-types';
const AbstractPermissionComponent = ({
  alternate,
  children,
  permission
}) => {
  const alternateOrNull = alternate || null;
  const alternateComponent = typeof alternateOrNull === 'function' ? alternateOrNull() : alternateOrNull;
  return permission ? children : alternateComponent;
};
AbstractPermissionComponent.propTypes = {
  alternate: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  children: PropTypes.element,
  permission: PropTypes.bool
};
export default AbstractPermissionComponent;