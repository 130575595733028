import { registerMutation } from 'data-fetching-client';
import { getQualityClient } from 'marketplace-ui-common/clients/qualityClient';
export const REPORT_REPLY = registerMutation({
  fieldName: 'reportReviewResponse',
  args: ['reviewId', 'replyId', 'reportReason', 'comment'],
  fetcher({
    reviewId,
    replyId,
    reportReason,
    comment
  }) {
    return getQualityClient('app').post(`/reviews/${reviewId}/replies/${replyId}/report`, {
      data: {
        reportReason,
        comment
      }
    });
  }
});