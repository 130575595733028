'use es6';

import { getCredentialDisplayName } from 'marketplace-ui-common/utils/credentials';
export const getCertificationsRequestState = state => state.certifications.requestState;
export const getCertifications = state => state.certifications.data;
export const getCertificationsKeyMirror = state =>
// TODO update this
state.certifications.data.reduce((obj, cert) => {
  obj[cert.id] = getCredentialDisplayName(cert, 'en');
  return obj;
}, {});