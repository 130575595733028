'use es6';

export const PROFILE_LEFT_COL_SIZE = {
  xs: 12,
  sm: 12,
  md: 5,
  lg: 4,
  xl: 4
};
export const PROFILE_RIGHT_COL_SIZE = {
  xs: 12,
  sm: 12,
  md: 7,
  lg: 8,
  xl: 8
};
export const LOGO_IMAGE_HEIGHT = 100;
export const LOGO_WRAPPER_PADDING_X = 40;
export const LOGO_WRAPPER_PADDING_Y = 16;
export const LOGO_WRAPPER_BOTTOM_MARGIN = 16;
export const LOGO_WRAPPER_OFFSET = LOGO_IMAGE_HEIGHT / 2 + LOGO_WRAPPER_PADDING_Y / 2 + LOGO_WRAPPER_BOTTOM_MARGIN / 2;
export const CARD_PADDING = 16;