import { registerQuery } from 'data-fetching-client';
import { getEcosystemClient } from 'marketplace-ui-common/clients/ecosystemClient';
export const GET_REVIEW_STATS = registerQuery({
  fieldName: 'reviewStatsResponse',
  args: ['appContext', 'offeringId', 'reviewType'],
  fetcher({
    appContext,
    offeringId,
    reviewType
  }) {
    const client = getEcosystemClient(appContext);
    return client.post('reviews/stats', {
      data: {
        entityId: offeringId,
        reviewType
      }
    });
  }
});