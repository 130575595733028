import { immerable } from 'immer';
import { freeze } from 'immutable-less/immer';
import { cloneDeep } from 'immutable-less/internal/cloneDeep';
import { BaseImmutableObject } from 'immutable-less/BaseImmutableObject';
import { produce } from 'immutable-less/internal/produce';
export function createImmutableObjectClass(defaults, serializationOptions) {
  class ImmutableObjectImplementation extends BaseImmutableObject {
    constructor(partial) {
      super();
      const jsonValue = Object.assign({}, defaults);
      if (partial) {
        Object.keys(defaults).forEach(key => {
          if (Object.hasOwnProperty.call(partial, key)) {
            jsonValue[key] = partial[key];
          }
        });
      }
      const recordValue = serializationOptions ? serializationOptions.fromJSON(jsonValue) : jsonValue; // only when RecordType = JSONType, enforced using the different overrides for ImmutableObject

      Object.assign(this, recordValue);
      freeze(this, true);
    }
    toJSON() {
      if (serializationOptions) {
        return serializationOptions.toJSON(this); // this type is already enforced when ImmutableObject() is called
      } else {
        return cloneDeep(Object.assign({}, this)); // only when RecordType = JSONType, enforced using the different overrides for ImmutableObject;
      }
    }
    clear() {
      return produce(this, draft => {
        Object.assign(draft, new ImmutableObjectImplementation());
      });
    }
  }
  Object.defineProperty(ImmutableObjectImplementation, immerable, {
    value: true
  });
  return ImmutableObjectImplementation;
}