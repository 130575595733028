export const ReportReviewReasons = {
  OFFENSIVE: 'OFFENSIVE',
  DUPLICATE: 'DUPLICATE',
  BAD_AUTHOR: 'BAD_AUTHOR',
  IRRELEVANT: 'IRRELEVANT',
  GIBBERISH: 'GIBBERISH',
  MALWARE: 'MALWARE',
  SELF_AUTHOR: 'SELF_AUTHOR',
  SOLICITATION: 'SOLICITATION',
  VIOLATION: 'VIOLATION',
  OTHER: 'OTHER'
};
export const DeprecatedReportReviewReasons = ['VIOLATION', 'OTHER'];
export const ReportReplyReasons = {
  VIOLATION: 'VIOLATION',
  OFFENSIVE: 'OFFENSIVE',
  BAD_AUTHOR: 'BAD_AUTHOR',
  OTHER: 'OTHER'
};
export const Reportables = {
  REVIEW: 'REVIEW',
  REPLY: 'REPLY'
};
export const ReportReasons = {
  [Reportables.REVIEW]: ReportReviewReasons,
  [Reportables.REPLY]: ReportReplyReasons
};