import { registerMutation } from 'data-fetching-client';
import { getQualityClient } from 'marketplace-ui-common/clients/qualityClient';
export const REPORT_REVIEW = registerMutation({
  fieldName: 'reportReviewResponse',
  args: ['reviewId', 'reportReason', 'comment'],
  fetcher({
    reviewId,
    reportReason,
    comment
  }) {
    return getQualityClient('app').post(`/reviews/${reviewId}/report`, {
      data: {
        reportReason,
        comment
      }
    });
  }
});