export const AL = 'alabama';
export const AK = 'alaska';
export const AZ = 'arizona';
export const AR = 'arkansas';
export const CA = 'california';
export const CO = 'colorado';
export const CT = 'connecticut';
export const DE = 'delaware';
export const DC = 'district-of-columbia';
export const FL = 'florida';
export const GA = 'georgia';
export const HI = 'hawaii';
export const ID = 'idaho';
export const IL = 'illinois';
export const IN = 'indiana';
export const IA = 'iowa';
export const KS = 'kansas';
export const KY = 'kentucky';
export const LA = 'louisiana';
export const ME = 'maine';
export const MD = 'maryland';
export const MA = 'massachusetts';
export const MI = 'michigan';
export const MN = 'minnesota';
export const MS = 'mississippi';
export const MO = 'missouri';
export const MT = 'montana';
export const NE = 'nebraska';
export const NV = 'nevada';
export const NH = 'new-hampshire';
export const NJ = 'new-jersey';
export const NM = 'new-mexico';
export const NY = 'new-york';
export const NC = 'north-carolina';
export const ND = 'north-dakota';
export const OH = 'ohio';
export const OK = 'oklahoma';
export const OR = 'oregon';
export const PA = 'pennsylvania';
export const RI = 'rhode-island';
export const SC = 'south-carolina';
export const SD = 'south-dakota';
export const TN = 'tennessee';
export const TX = 'texas';
export const UT = 'utah';
export const VT = 'vermont';
export const VA = 'virginia';
export const WA = 'washington';
export const WV = 'west-virginia';
export const WI = 'wisconsin';
export const WY = 'wyoming';