'use es6';

import { useMemo } from 'react';
import { parse as parseParams } from 'hub-http/helpers/params';

/**
 * @deprecated moved to marketplace-ui-common/hooks
 */
export const useShortlinkParams = (trackingParams = {}) => {
  const {
    search
  } = window.location;
  const shortlinkParams = useMemo(() => {
    const currentParams = parseParams(search.slice(1));
    return Object.assign({}, trackingParams, currentParams);
  }, [search, trackingParams]);
  return shortlinkParams;
};