const uniqueIdCache = new WeakMap();
let nextId = 1; // skipping 0 so all IDs are truthy
function generateUniqueId() {
  return nextId++;
}

/**
 * Generates and caches (in a memory-safe manner) a unique ID for a given object
 * by its reference. Useful for factoring the uniqueness of an object into a
 * memoization key string or react key.
 *
 * Throws an error for primitives like null, undefined, and any string, number,
 * or boolean. Supports only Objects, Arrays, Functions, and Regexes.
 */
export function uniqueIdForObject(object) {
  let id = uniqueIdCache.get(object);
  if (id === undefined) {
    id = generateUniqueId();
    uniqueIdCache.set(object, id);
  }
  return id;
}