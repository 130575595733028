// This is the list that the Solutions Directory historically used
// on the Profile and in the Industry filter
// We are migrating all other Industry uses (e.g. ReviewerInfo Industry list and filter)
// onto this centralized list

export const ARCHITECTURE = 'ARCHITECTURE';
export const AUTOMOTIVE_TRANSPORT = 'AUTOMOTIVE_TRANSPORT';
export const BANKING_FINANCIAL_SERVICES = 'BANKING_FINANCIAL_SERVICES';
export const BUSINESS_SERVICES_GENERAL = 'BUSINESS_SERVICES_GENERAL';
export const CONSTRUCTION_RENOVATION = 'CONSTRUCTION_RENOVATION';
export const CONSULTING_ADVISORY = 'CONSULTING_ADVISORY';
export const CONSUMER_PRODUCTS = 'CONSUMER_PRODUCTS';
export const CONSUMER_SERVICES = 'CONSUMER_SERVICES';
export const DATA_PROCESSING_HOSTING = 'DATA_PROCESSING_HOSTING';
export const ECOMMERCE = 'ECOMMERCE';
export const EDUCATION = 'EDUCATION';
export const ELECTRONICS = 'ELECTRONICS';
export const ENERGY = 'ENERGY';
export const ENTERTAINMENT = 'ENTERTAINMENT';
export const FINANCE_INSURANCE = 'FINANCE_INSURANCE';
export const GOVERNMENT = 'GOVERNMENT';
export const HEALTH = 'HEALTH';
export const HOSPITALITY = 'HOSPITALITY';
export const LEGAL_SERVICES = 'LEGAL_SERVICES';
export const MANUFACTURING = 'MANUFACTURING';
export const MARKETING_SERVICES = 'MARKETING_SERVICES';
export const MEDIA_PUBLISHING = 'MEDIA_PUBLISHING';
export const MEDICAL_WELLNESS = 'MEDICAL_WELLNESS';
export const NON_PROFIT = 'NON_PROFIT';
export const OIL_GAS = 'OIL_GAS';
export const OTHER = 'OTHER';
export const REAL_ESTATE = 'REAL_ESTATE';
export const RECRUITING_STAFFING = 'RECRUITING_STAFFING';
export const RETAIL = 'RETAIL';
export const SHIPPING = 'SHIPPING';
export const TECHNOLOGY_HARDWARE_STORAGE = 'TECHNOLOGY_HARDWARE_STORAGE';
export const TECHNOLOGY_SOFTWARE = 'TECHNOLOGY_SOFTWARE';
export const TELECOM_MOBILE_COMMUNICATIONS = 'TELECOM_MOBILE_COMMUNICATIONS';
export const TOURISM_TRAVEL = 'TOURISM_TRAVEL';
export const TRANSPORTATION_STORAGE = 'TRANSPORTATION_STORAGE';
export const UTILITIES = 'UTILITIES';
export const WASTE_MANAGEMENT = 'WASTE_MANAGEMENT';