import { parse as parseQuery, stringify as stringifyQuery } from 'hub-http/helpers/params';
import { storefrontFiltersInitialState } from '../constants/StorefrontFilters';
import { getPageNumberFromOffset, getOffsetFromPageNumber } from '../utils/pagination';
/* @ts-expect-error module is untyped */
import { getPathFromSearchKey, getSearchKeyFromPath } from '../utils/params';
/* @ts-expect-error module is untyped */
import { getSearchValueFromFilterKey } from '../utils/filters';
import { CERTIFICATION, ACCREDITATION, CREDENTIAL, OFFSET, COUNTRY } from '../constants/search/FilterKey';
import { DEFAULT_SEARCH_LIMIT, ECOSYSTEM_QUERY_PREFIX, FILTER_TAG_KEYS, QUERY_KEYS, PATH_KEYS, FilterKeyToApiQueryKey, MULTI_SELECT_FILTER_KEYS
/* @ts-expect-error module is untyped */ } from '../constants/search/Filters';
// util that will replace StorefrontFilters.toLocation
export const fromFilterStateToLocation = (filterState, catalogServices) => {
  const pathParams = PATH_KEYS.reduce((params, key) => {
    if (key === COUNTRY && filterState[key] === 'ANY') {
      return params;
    }
    if (filterState[key] && filterState[key].length === 1) {
      return [...params, getPathFromSearchKey(key, filterState[key][0], catalogServices)];
    } else if (typeof filterState[key] === 'string' && filterState[key].length > 0) {
      return [...params, getPathFromSearchKey(key, filterState[key], catalogServices)];
    }
    return params;
  }, []);
  if (pathParams.length === 0) {
    pathParams.push('all');
  }
  if (filterState[OFFSET]) {
    const pageNumber = getPageNumberFromOffset(filterState[OFFSET], DEFAULT_SEARCH_LIMIT);
    pathParams.push(`page/${pageNumber}`);
  }
  const queryParams = [...PATH_KEYS, ...QUERY_KEYS].reduce((query, key) => {
    const value = filterState[key];
    if (value && value !== storefrontFiltersInitialState[key]) {
      if (PATH_KEYS.indexOf(key) !== -1 && (typeof value === 'string' || value.length <= 1)) {
        return query;
      }
      return Object.assign({}, query, {
        [`${ECOSYSTEM_QUERY_PREFIX}${key}`]: Array.isArray(value) ? value.map(val => getPathFromSearchKey(key, val, catalogServices)) : getPathFromSearchKey(key, value, catalogServices)
      });
    }
    return query;
  }, {});
  return {
    pathname: `/${pathParams.join('/')}`,
    search: `?${stringifyQuery(queryParams)}`
  };
};

// util that will replace StorefrontFilters.fromLocation
export const fromLocationToFilterState = (location, params, catalogServices = []) => {
  const query = parseQuery(location.search.slice(1));
  const filters = [...QUERY_KEYS, ...PATH_KEYS].reduce((result, key) => {
    const prefixedKey = `${ECOSYSTEM_QUERY_PREFIX}${key}`;
    const value = query[prefixedKey] || query[key];
    if (!value) {
      return result;
    }
    if (typeof storefrontFiltersInitialState[key] === 'string') {
      return Object.assign({}, result, {
        [key]: getSearchKeyFromPath(key, value, catalogServices)
      });
    }
    return Object.assign({}, result, {
      [key]: typeof value === 'string' ?
      // ignore invalid query params
      getSearchKeyFromPath(key, value, catalogServices) ? [getSearchKeyFromPath(key, value, catalogServices)] : [] : value.reduce((values, val) => {
        // ignore invalid query params
        if (getSearchKeyFromPath(key, val, catalogServices)) {
          return [...values, getSearchKeyFromPath(key, val, catalogServices)];
        }
        return values;
      }, [])
    });
  }, {});
  const page = params.page || query.page;
  if (page) {
    const pageNumber = parseInt(page, 10);
    filters[OFFSET] = getOffsetFromPageNumber(pageNumber, DEFAULT_SEARCH_LIMIT);
  }
  Object.keys(params).forEach(key => {
    if (params[key]) {
      const searchKey = getSearchKeyFromPath(key, params[key], catalogServices);
      if (searchKey) {
        // if the filter is multiselectable, ensure that it is wrapped in an array
        filters[key] = MULTI_SELECT_FILTER_KEYS.indexOf(key) !== -1 ? [...(filters[key] || []), searchKey] : searchKey;
      }
    }
  });
  return filters;
};

// util that will replace StorefrontFilters.toApiSearchQuery
export const fromFilterStateToQuery = filterState => {
  const values = Object.keys(FilterKeyToApiQueryKey).reduce((result, key) => {
    let value;
    if (filterState[key]) {
      if (key === CERTIFICATION || key === ACCREDITATION) {
        key = CREDENTIAL;
        const credentialValues = [...filterState[CERTIFICATION], ...filterState[ACCREDITATION]].filter(val => val); // filter out empty strings
        value = getSearchValueFromFilterKey(key, credentialValues);
        return value.values.length ? Object.assign({}, result, {
          [FilterKeyToApiQueryKey[key]]: value
        }) : result;
      } else {
        value = getSearchValueFromFilterKey(key, filterState[key]);
      }
      if (typeof filterState[key] === 'string' || typeof filterState[key] === 'number' || filterState[key].length) {
        return Object.assign({}, result, {
          [FilterKeyToApiQueryKey[key]]: value
        });
      }
    }
    return result;
  }, {});
  return Object.assign({}, values, {
    limit: DEFAULT_SEARCH_LIMIT
  });
};

// util that will return an array of {key, value} objects for creating filter tags
export const fromStateToFilterTags = filterState => {
  return FILTER_TAG_KEYS.reduce((result, filterKey) => {
    if (filterKey in filterState && filterState[filterKey]) {
      if (typeof filterState[filterKey] === 'string' || typeof filterState[filterKey] === 'number') {
        return [...result, {
          key: filterKey,
          value: filterState[filterKey]
        }];
      } else if (filterState[filterKey].length > 0) {
        return [...result, ...filterState[filterKey].map(filterValue => ({
          key: filterKey,
          value: filterValue
        }))];
      }
    }
    return result;
  }, []);
};