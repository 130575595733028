import * as PartnerTypes from '../constants/PartnerType';
export const getProfileTierLabelI18nKey = (tier, partnerType, labelKey) => {
  switch (partnerType) {
    case PartnerTypes.PROVIDER:
      {
        return `marketplace-ui-solutions-core.profileTier.${PartnerTypes.PROVIDER}.${labelKey}`;
      }
    case PartnerTypes.PARTNER:
    case PartnerTypes.RESELLER:
      {
        return `marketplace-ui-solutions-core.profileTier.${PartnerTypes.PARTNER}.${tier}.${labelKey}`;
      }
    case PartnerTypes.DIRECTORY_USER:
    default:
      {
        return '';
      }
  }
};