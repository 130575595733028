import * as ReviewTypes from './ReviewType';
export const RatingAxes = {
  OVERALL: 'OVERALL',
  VALUE: 'VALUE',
  // Apps
  USABILITY: 'USABILITY',
  FUNCTIONALITY: 'FUNCTIONALITY',
  SUPPORT_DOCS: 'SUPPORT_DOCS',
  //Themes
  PERFORMANCE: 'PERFORMANCE',
  // Profiles
  RESULT_QUALITY: 'RESULT_QUALITY',
  CUSTOMER_SERVICE: 'CUSTOMER_SERVICE',
  COMMUNICATION: 'COMMUNICATION'
};
export const AppQualityRatingsOrder = [RatingAxes.USABILITY, RatingAxes.FUNCTIONALITY, RatingAxes.VALUE, RatingAxes.SUPPORT_DOCS];
export const AppRatingAxes = [RatingAxes.OVERALL, RatingAxes.VALUE, RatingAxes.USABILITY, RatingAxes.FUNCTIONALITY, RatingAxes.SUPPORT_DOCS];
export const ThemeRatingAxes = [RatingAxes.OVERALL, RatingAxes.USABILITY, RatingAxes.PERFORMANCE, RatingAxes.VALUE, RatingAxes.SUPPORT_DOCS];
export const ModuleRatingAxes = [RatingAxes.OVERALL, RatingAxes.USABILITY, RatingAxes.PERFORMANCE, RatingAxes.VALUE, RatingAxes.SUPPORT_DOCS];
export const QualityRatingsByReviewType = {
  [ReviewTypes.APP]: AppRatingAxes.slice(1),
  [ReviewTypes.THEME]: ThemeRatingAxes.slice(1),
  [ReviewTypes.MODULE]: ModuleRatingAxes.slice(1)
};