import { createImmutableObjectClass } from 'immutable-less';
import I18n from 'I18n';
import isValidI18nKey from 'I18n/utils/isValidI18nKey';
const officeLocationDefaults = {
  country: '',
  street1: '',
  street2: '',
  locality: '',
  state: '',
  zip: '',
  full: '',
  autocompleted: false,
  remote: false
};
export class OfficeLocation extends createImmutableObjectClass(officeLocationDefaults) {
  buildFullCity() {
    if (this.remote && this.onlyHasCountryProvided()) {
      const key = `SharedI18nStrings.countryCodes.${this.country}`;
      if (isValidI18nKey(key)) {
        return I18n.text(key);
      }
      return this.full;
    }
    const addressParts = ['locality', 'state', 'country'].reduce((result, part) => {
      if (this.get(part)) {
        result.push(this.get(part));
      }
      return result;
    }, []);
    return addressParts.join(', ');
  }
  buildFullAddress() {
    if (this.remote && this.onlyHasCountryProvided()) {
      const key = `SharedI18nStrings.countryCodes.${this.country}`;
      if (isValidI18nKey(key)) {
        return I18n.text(key);
      }
      return this.full;
    }
    const addressParts = ['street1', 'street2', 'locality', 'state', 'zip', 'country'].reduce((result, part) => {
      if (part === 'country' && this.get(part) && this.get(part).trim() !== '') {
        // use full country name to ensure google map link redirects to /place url
        const key = `SharedI18nStrings.countryCodes.${this.get(part).trim()}`;
        result.push(I18n.text(key));
      } else if (this.get(part) && this.get(part).trim() !== '') {
        result.push(this.get(part).trim());
      }
      return result;
    }, []);
    return addressParts.join(', ');
  }
  onlyHasCountryProvided() {
    return this.country && !this.street1 && !this.street2 && !this.locality && !this.state && !this.zip;
  }
}