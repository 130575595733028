export const AD = 'AD';
export const AE = 'AE';
export const AF = 'AF';
export const AG = 'AG';
export const AI = 'AI';
export const AL = 'AL';
export const AM = 'AM';
export const AN = 'AN';
export const AO = 'AO';
export const AP = 'AP';
export const AQ = 'AQ';
export const AR = 'AR';
export const AS = 'AS';
export const AT = 'AT';
export const AU = 'AU';
export const AW = 'AW';
export const AX = 'AX';
export const AZ = 'AZ';
export const BA = 'BA';
export const BB = 'BB';
export const BD = 'BD';
export const BE = 'BE';
export const BF = 'BF';
export const BG = 'BG';
export const BH = 'BH';
export const BI = 'BI';
export const BJ = 'BJ';
export const BL = 'BL';
export const BM = 'BM';
export const BN = 'BN';
export const BO = 'BO';
export const BQ = 'BQ';
export const BR = 'BR';
export const BS = 'BS';
export const BT = 'BT';
export const BV = 'BV';
export const BW = 'BW';
export const BY = 'BY';
export const BZ = 'BZ';
export const CA = 'CA';
export const CC = 'CC';
export const CD = 'CD';
export const CF = 'CF';
export const CG = 'CG';
export const CH = 'CH';
export const CI = 'CI';
export const CK = 'CK';
export const CL = 'CL';
export const CM = 'CM';
export const CN = 'CN';
export const CO = 'CO';
export const CR = 'CR';
export const CU = 'CU';
export const CV = 'CV';
export const CW = 'CW';
export const CX = 'CX';
export const CY = 'CY';
export const CZ = 'CZ';
export const DE = 'DE';
export const DJ = 'DJ';
export const DK = 'DK';
export const DM = 'DM';
export const DO = 'DO';
export const DZ = 'DZ';
export const EC = 'EC';
export const EE = 'EE';
export const EG = 'EG';
export const EH = 'EH';
export const ER = 'ER';
export const ES = 'ES';
export const ET = 'ET';
export const EU = 'EU';
export const FI = 'FI';
export const FJ = 'FJ';
export const FK = 'FK';
export const FM = 'FM';
export const FO = 'FO';
export const FR = 'FR';
export const GA = 'GA';
export const GB = 'GB';
export const GD = 'GD';
export const GE = 'GE';
export const GF = 'GF';
export const GG = 'GG';
export const GH = 'GH';
export const GI = 'GI';
export const GL = 'GL';
export const GM = 'GM';
export const GN = 'GN';
export const GP = 'GP';
export const GQ = 'GQ';
export const GR = 'GR';
export const GS = 'GS';
export const GT = 'GT';
export const GU = 'GU';
export const GW = 'GW';
export const GY = 'GY';
export const HK = 'HK';
export const HM = 'HM';
export const HN = 'HN';
export const HR = 'HR';
export const HT = 'HT';
export const HU = 'HU';
export const ID = 'ID';
export const IE = 'IE';
export const IL = 'IL';
export const IM = 'IM';
export const IN = 'IN';
export const IO = 'IO';
export const IQ = 'IQ';
export const IR = 'IR';
export const IS = 'IS';
export const IT = 'IT';
export const JE = 'JE';
export const JM = 'JM';
export const JO = 'JO';
export const JP = 'JP';
export const KE = 'KE';
export const KG = 'KG';
export const KH = 'KH';
export const KI = 'KI';
export const KM = 'KM';
export const KN = 'KN';
export const KP = 'KP';
export const KR = 'KR';
export const KW = 'KW';
export const KY = 'KY';
export const KZ = 'KZ';
export const LA = 'LA';
export const LB = 'LB';
export const LC = 'LC';
export const LI = 'LI';
export const LK = 'LK';
export const LR = 'LR';
export const LS = 'LS';
export const LT = 'LT';
export const LU = 'LU';
export const LV = 'LV';
export const LY = 'LY';
export const MA = 'MA';
export const MC = 'MC';
export const MD = 'MD';
export const ME = 'ME';
export const MF = 'MF';
export const MG = 'MG';
export const MH = 'MH';
export const MK = 'MK';
export const ML = 'ML';
export const MM = 'MM';
export const MN = 'MN';
export const MO = 'MO';
export const MP = 'MP';
export const MQ = 'MQ';
export const MR = 'MR';
export const MS = 'MS';
export const MT = 'MT';
export const MU = 'MU';
export const MV = 'MV';
export const MW = 'MW';
export const MX = 'MX';
export const MY = 'MY';
export const MZ = 'MZ';
export const NA = 'NA';
export const NC = 'NC';
export const NE = 'NE';
export const NF = 'NF';
export const NG = 'NG';
export const NI = 'NI';
export const NL = 'NL';
export const NO = 'NO';
export const NP = 'NP';
export const NR = 'NR';
export const NU = 'NU';
export const NZ = 'NZ';
export const OM = 'OM';
export const PA = 'PA';
export const PE = 'PE';
export const PF = 'PF';
export const PG = 'PG';
export const PH = 'PH';
export const PK = 'PK';
export const PL = 'PL';
export const PM = 'PM';
export const PN = 'PN';
export const PR = 'PR';
export const PS = 'PS';
export const PT = 'PT';
export const PW = 'PW';
export const PY = 'PY';
export const QA = 'QA';
export const RE = 'RE';
export const RO = 'RO';
export const RS = 'RS';
export const RU = 'RU';
export const RW = 'RW';
export const SA = 'SA';
export const SB = 'SB';
export const SC = 'SC';
export const SD = 'SD';
export const SE = 'SE';
export const SG = 'SG';
export const SH = 'SH';
export const SI = 'SI';
export const SJ = 'SJ';
export const SK = 'SK';
export const SL = 'SL';
export const SM = 'SM';
export const SN = 'SN';
export const SO = 'SO';
export const SR = 'SR';
export const SS = 'SS';
export const ST = 'ST';
export const SV = 'SV';
export const SX = 'SX';
export const SY = 'SY';
export const SZ = 'SZ';
export const TC = 'TC';
export const TD = 'TD';
export const TF = 'TF';
export const TG = 'TG';
export const TH = 'TH';
export const TJ = 'TJ';
export const TK = 'TK';
export const TL = 'TL';
export const TM = 'TM';
export const TN = 'TN';
export const TO = 'TO';
export const TR = 'TR';
export const TT = 'TT';
export const TV = 'TV';
export const TW = 'TW';
export const TZ = 'TZ';
export const UA = 'UA';
export const UG = 'UG';
export const UM = 'UM';
export const US = 'US';
export const UY = 'UY';
export const UZ = 'UZ';
export const VA = 'VA';
export const VC = 'VC';
export const VE = 'VE';
export const VG = 'VG';
export const VI = 'VI';
export const VN = 'VN';
export const VU = 'VU';
export const WF = 'WF';
export const WS = 'WS';
export const YE = 'YE';
export const YT = 'YT';
export const ZA = 'ZA';
export const ZM = 'ZM';
export const ZW = 'ZW';