import * as t from 'ts-schema';
const collectionListingSchema = t.object({
  canonicalUrl: t.union(t.string, t.literal(null)),
  description: t.string,
  displayed: t.boolean,
  htmlPageTitle: t.string,
  id: t.number,
  metaDescription: t.string,
  name: t.string,
  slug: t.string,
  tagline: t.string,
  title: t.string,
  updatedAt: t.number,
  updatedBy: t.union(t.string, t.literal(undefined))
});
export const collectionSchema = t.object({
  createdAt: t.union(t.number, t.literal(undefined)),
  draftListing: t.optional(collectionListingSchema),
  id: t.number,
  internalName: t.string,
  pendingSubmission: t.union(t.boolean, t.literal(undefined)),
  publishedListing: t.optional(collectionListingSchema),
  type: t.union(t.literal('DYNAMIC'), t.literal('STATIC'))
});
export const relevantCollectionIdsResponseSchema = t.object({
  collectionIds: t.array(t.number)
});
export const collectionsResponseSchema = t.object({
  collections: t.array(collectionSchema)
});