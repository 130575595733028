'use es6';

import createPublicI18nProvider from 'I18n/init/providers/createPublicI18nProvider';
import BaseApp from 'ecosystem-marketplace-solutions-base/App';
import { setAppContext } from 'ecosystem-marketplace-solutions-base/actions/AppActions';
import { setEcosystemApiBase } from 'ecosystem-marketplace-solutions-base/clients/ecosystemClient';
import { setProfilesApiBase } from 'ecosystem-marketplace-solutions-base/clients/profilesClient';
import { setMarketplaceSearchBase } from 'ecosystem-marketplace-solutions-base/clients/marketplaceSearchClient';
import * as AppContext from 'ecosystem-marketplace-solutions-base/constants/AppContext';
import { setApiClient } from 'ecosystem-marketplace-solutions-base/utils/apiClient';
import { getPublicRootUrl } from 'ecosystem-marketplace-solutions-base/utils/urls';
import noAuthApiClient from 'hub-http/clients/noAuthApiClient';
import rhumbConfig from '../rhumb-config.yaml';
import Application from './containers/Application';
export default class PublicApp extends BaseApp {
  initialize() {
    const promises = [];
    setEcosystemApiBase('ecosystem/public/v1');
    setProfilesApiBase('marketplace/profiles/public/v1');
    setMarketplaceSearchBase('marketplace-search/public/v1');
    this.setAppContext();
    this.setApiClient();
    const [I18nProvider, langAvailable] = this.setupI18n();
    I18nProvider.setLocale({
      locale: this.getLocale(),
      langEnabled: true
    });
    promises.push(langAvailable);
    return Promise.all(promises);
  }
  setupI18n() {
    const I18nProvider = createPublicI18nProvider({
      manuallySetLocale: true
    });
    const langAvailable = this.registerI18n(I18nProvider);
    return [I18nProvider, langAvailable];
  }
  setAppContext() {
    this.store.dispatch(setAppContext(AppContext.PUBLIC));
  }
  setApiClient() {
    setApiClient(noAuthApiClient);
  }
  getLocale() {
    return window.I18N_MANUAL_LANG || 'en';
  }
  getRootUrl() {
    return getPublicRootUrl(this.getLocale());
  }
  getRhumbConfig() {
    return rhumbConfig;
  }
  getApplicationComponent() {
    return Application;
  }
}