import { PANTERA, LORAX } from 'HubStyleTokens/colors';
import * as ProfileLevelColors from '../constants/ProfileLevelColor';
import * as PartnerTiers from '../constants/PartnerTier';
import * as PartnerTypes from '../constants/PartnerType';
const getUseFromColor = color => {
  if (color === PANTERA) {
    return 'pantera';
  } else if (color === LORAX) {
    return 'lorax';
  } else {
    return 'koala';
  }
};
export const getProfileLevelColorUse = (partnerType, tier) => {
  if (tier === PartnerTiers.NONE) {
    if (partnerType === PartnerTypes.PARTNER || partnerType === PartnerTypes.RESELLER) {
      return getUseFromColor(ProfileLevelColors.PARTNER_SOLUTIONS);
    } else if (partnerType === PartnerTypes.PROVIDER) {
      return getUseFromColor(ProfileLevelColors.PROVIDER);
    } else {
      return undefined;
    }
  } else {
    return getUseFromColor(ProfileLevelColors[`PARTNER_${tier}`]);
  }
};