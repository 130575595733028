'use es6';

import { fetchAcademyCertifications } from '../clients/credentialsClient';
import * as ActionTypes from './ActionTypes';
export const getAcademyCertifications = () => dispatch => {
  dispatch({
    type: ActionTypes.ACADEMY_CERTIFICATIONS_REQUESTED
  });
  return fetchAcademyCertifications().then(resp => {
    dispatch({
      type: ActionTypes.ACADEMY_CERTIFICATIONS_RECEIVED,
      payload: resp
    });
  }).catch(error => {
    dispatch({
      type: ActionTypes.ACADEMY_CERTIFICATIONS_FAILED,
      payload: error
    });
  });
};