export const AU_MELBOURNE = 'melbourne-top-digital-agencies';
export const AU_SYDNEY = 'sydney-top-digital-agencies';
export const GB_LONDON = 'london-uk';
export const GB_MANCHESTER = 'manchester';
export const SE_STOCKHOLM = 'stockholm-top-digital-agencies';
export const US_BOSTON = 'boston-ma';
export const US_CHICAGO = 'chicago-top-digital-agencies';
export const US_CLEVELAND = 'cleveland-top-digital-agencies';
export const US_DALLAS_FORT_WORTH = 'dallas-fort-worth';
export const US_DENVER = 'denver-top-digital-agencies';
export const US_HOUSTON = 'houston';
export const US_LOS_ANGELES = 'los-angeles';
export const US_NEW_YORK_CITY = 'new-york-city';
export const US_PHILADELPHIA = 'philadelphia-top-digital-agencies';
export const US_SAN_DIEGO = 'san-diego-top-digital-agencies';