export const ARCHITECTURE = 'architecture';
export const AUTOMOTIVE_TRANSPORT = 'automotive-transport';
export const BANKING_FINANCIAL_SERVICES = 'banking-financial-services';
export const BUSINESS_SERVICES_GENERAL = 'business-services-general';
export const CONSTRUCTION_RENOVATION = 'construction-renovation';
export const CONSULTING_ADVISORY = 'consulting-advisory';
export const CONSUMER_PRODUCTS = 'consumer-products';
export const CONSUMER_SERVICES = 'consumer-services';
export const DATA_PROCESSING_HOSTING = 'data-processing-hosting';
export const ECOMMERCE = 'ecommerce';
export const EDUCATION = 'education';
export const ELECTRONICS = 'electronics';
export const ENERGY = 'energy';
export const ENTERTAINMENT = 'entertainment';
export const FINANCE_INSURANCE = 'finance-insurance';
export const GOVERNMENT = 'government';
export const HEALTH = 'health';
export const HOSPITALITY = 'hospitality';
export const LEGAL_SERVICES = 'legal-services';
export const MANUFACTURING = 'manufacturing';
export const MARKETING_SERVICES = 'marketing-services';
export const MEDIA_PUBLISHING = 'media-publishing';
export const MEDICAL_WELLNESS = 'medical-wellness';
export const NON_PROFIT = 'non-profit';
export const OIL_GAS = 'oil-gas';
export const OTHER = 'other';
export const REAL_ESTATE = 'real-estate';
export const RECRUITING_STAFFING = 'recruiting-staffing';
export const RETAIL = 'retail';
export const SHIPPING = 'shipping';
export const TECHNOLOGY_HARDWARE_STORAGE = 'technology-hardware-storage';
export const TECHNOLOGY_SOFTWARE = 'technology-software';
export const TELECOM_MOBILE_COMMUNICATIONS = 'telecom-mobile-communications';
export const TOURISM_TRAVEL = 'tourism-travel';
export const TRANSPORTATION_STORAGE = 'transportation-storage';
export const UTILITIES = 'utilities';
export const WASTE_MANAGEMENT = 'waste-management';