'use es6';

import { fetchPartnerAwards } from '../clients/credentialsClient';
import * as ActionTypes from './ActionTypes';
export const getPartnerAwards = () => dispatch => {
  dispatch({
    type: ActionTypes.PARTNER_AWARDS_REQUESTED
  });
  return fetchPartnerAwards().then(resp => {
    dispatch({
      type: ActionTypes.PARTNER_AWARDS_RECEIVED,
      payload: resp
    });
  }).catch(error => {
    dispatch({
      type: ActionTypes.PARTNER_AWARDS_FAILED,
      payload: error
    });
  });
};