'use es6';

import { createBrowserHistory } from 'history';
let history;
export const createHistory = basename => {
  history = createBrowserHistory({
    basename
  });
  return history;
};
export const push = (...args) => {
  if (!history) {
    throw new Error('history not initialized');
  }
  return history.push(...args);
};
export const replace = (...args) => {
  if (!history) {
    throw new Error('history not initialized');
  }
  return history.replace(...args);
};