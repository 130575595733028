'use es6';

import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import NotificationMiddleware from 'ReduxMessenger/middleware/NotificationMiddleware';
import filterMiddleware from '../middleware/filters';
import rootReducer from '../reducers';
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const createStoreWithMiddleware = composeEnhancers(applyMiddleware(thunk, NotificationMiddleware, filterMiddleware))(createStore);
export default function configureStore(initialState = {}) {
  return createStoreWithMiddleware(rootReducer, initialState);
}