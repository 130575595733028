import { GREAT_WHITE, MARIGOLD, SLINKY, FLINT } from 'HubStyleTokens/colors';
import { MICROCOPY_SIZE, BASE_FONT_SIZE, ROOT_FONT_SIZE } from 'HubStyleTokens/sizes';
export const STAR_RATING = 'STAR_RATING';
export const STAR_RATING_FILLED = 'STAR_RATING_FILLED';
export const STAR_RATING_EMPTY_STATE = 'STAR_RATING_EMPTY_STATE';
export const PILL_RATING = 'PILL_RATING';
export const RatingTypesTemplate = {
  STAR_RATING: {
    checkedColor: MARIGOLD,
    uncheckedColor: FLINT,
    checkedIcon: 'favorite',
    uncheckedIcon: 'favoriteHollow'
  },
  STAR_RATING_FILLED: {
    checkedColor: MARIGOLD,
    uncheckedColor: GREAT_WHITE,
    checkedIcon: 'favorite',
    uncheckedIcon: 'favorite'
  },
  STAR_RATING_EMPTY_STATE: {
    checkedColor: GREAT_WHITE,
    uncheckedColor: GREAT_WHITE,
    checkedIcon: 'favorite',
    uncheckedIcon: 'favorite'
  },
  PILL_RATING: {
    checkedColor: SLINKY,
    uncheckedColor: GREAT_WHITE,
    checkedIcon: 'minus',
    uncheckedIcon: 'minus'
  }
};
export const LabelSizesTemplate = {
  sm: MICROCOPY_SIZE,
  small: MICROCOPY_SIZE,
  md: BASE_FONT_SIZE,
  medium: BASE_FONT_SIZE,
  large: ROOT_FONT_SIZE,
  lg: ROOT_FONT_SIZE
};