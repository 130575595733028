'use es6';

import makePermissionComponent from './makePermissionComponent';
import { createSelector } from 'reselect';
const getGates = state => state.auth && state.auth.gates;
const getScopes = state => state.auth && state.auth.user && state.auth.user.scopes;
const getPermissionName = (state, ownProps) => ownProps.name;
const hasGate = createSelector([getGates, getPermissionName], (gates, permissionName) => gates.includes(permissionName));
const hasScope = createSelector([getScopes, getPermissionName], (scopes, permissionName) => scopes.includes(permissionName));
const Gates = makePermissionComponent(hasGate);
const Scopes = makePermissionComponent(hasScope);
export { Gates, Scopes, makePermissionComponent };