import { useEffect, useMemo } from 'react';
import { useQuery } from 'data-fetching-client';
import * as RequestState from 'marketplace-ui-common/requestStates/RequestState';
import { isSucceeded } from 'marketplace-ui-common/requestStates/RequestStateUtils';
import { GET_REVIEWABILITY } from '../queries/reviewabilityQuery';
import { PENDING } from '../constants/UnreviewableReason';
export function useReviewability({
  entityId,
  entityPortalId,
  isPublicApp,
  reviewPending,
  reviewType
}) {
  const {
    error,
    loading,
    refetch
  } = useQuery(GET_REVIEWABILITY, {
    variables: {
      type: reviewType,
      entityId,
      entityPortalId
    },
    skip: isPublicApp
  });
  const reviewabilityRequestStatus = loading ? RequestState.PENDING : RequestState.SUCCEEDED;
  useEffect(() => {
    if (!isPublicApp && entityId && entityPortalId) {
      refetch().catch(() => {
        // pass through
      });
    }
  }, [entityId, entityPortalId, isPublicApp, refetch]);
  const unreviewableReason = useMemo(() => {
    if (reviewPending) {
      return `ReviewError.${PENDING}`;
    }
    if (error && isSucceeded(reviewabilityRequestStatus)) {
      try {
        const data = JSON.parse(error.networkError.data);
        if ('subCategory' in data) {
          return data.subCategory;
        }
        if ('errors' in data) {
          return data.errors[0].subCategory;
        }
      } catch (e) {
        return 'ReviewError.GENERIC_UNREVIEWABLE';
      }
    }
    return null;
  }, [reviewPending, reviewabilityRequestStatus, error]);
  return {
    unreviewableReason,
    requestState: loading || !entityId || !entityPortalId ? RequestState.PENDING : RequestState.SUCCEEDED
  };
}