export const CAROUSEL_SERVICES_QA = [{
  id: 1309,
  iconName: 'website'
},
// website development
{
  id: 1308,
  iconName: 'customModules'
},
// website design
{
  id: 1292,
  iconName: 'integrations'
},
// custom api integrations
{
  id: 48553,
  iconName: 'reports'
},
// account based marketing
{
  id: 1301,
  iconName: 'salesQuote'
},
// paid advertising
{
  id: 1298,
  iconName: 'tag'
},
// full inbound marketing services
{
  id: 1288,
  iconName: 'edit'
},
// content creation
{
  id: 1305,
  iconName: 'workflows'
},
// sales enablement
{
  id: 1297,
  iconName: 'logEmail'
},
// email marketing
{
  id: 1304,
  iconName: 'cap'
},
// sales coaching and training
{
  id: 1293,
  iconName: 'contactDuplicate'
},
// customer marketing
{
  id: 1303,
  iconName: 'salesTemplates'
} // sales and marketing alignment
];
export const CAROUSEL_SERVICES_PROD = [{
  id: 24,
  iconName: 'website'
},
// website development
{
  id: 23,
  iconName: 'customModules'
},
// website design
{
  id: 7,
  iconName: 'integrations'
},
// custom api integrations
{
  id: 39001,
  iconName: 'reports'
},
// account based marketing
{
  id: 16,
  iconName: 'salesQuote'
},
// paid advertising
{
  id: 13,
  iconName: 'tag'
},
// full inbound marketing services
{
  id: 3,
  iconName: 'edit'
},
// content creation
{
  id: 20,
  iconName: 'workflows'
},
// sales enablement
{
  id: 12,
  iconName: 'logEmail'
},
// email marketing
{
  id: 19,
  iconName: 'cap'
},
// sales coaching and training
{
  id: 8,
  iconName: 'contactDuplicate'
},
// customer marketing
{
  id: 1,
  iconName: 'salesTemplates'
} // sales and marketing alignment
];
export const ACCREDITATION_CARD_IDS = [43003,
// CRM Implementation
52001,
// HubSpot Onboarding
43004,
// Advanced CMS Implementation
78002,
// Custom Integration
126001,
// Data Migration
63001,
// Platform Enablement
126002 // Solution Architecture Design
];