export const AU_MELBOURNE = 'AU_MELBOURNE';
export const AU_SYDNEY = 'AU_SYDNEY';
export const GB_LONDON = 'GB_LONDON';
export const GB_MANCHESTER = 'GB_MANCHESTER';
export const SE_STOCKHOLM = 'SE_STOCKHOLM';
export const US_BOSTON = 'US_BOSTON';
export const US_CHICAGO = 'US_CHICAGO';
export const US_CLEVELAND = 'US_CLEVELAND';
export const US_DALLAS_FORT_WORTH = 'US_DALLAS_FORT_WORTH';
export const US_DENVER = 'US_DENVER';
export const US_HOUSTON = 'US_HOUSTON';
export const US_LOS_ANGELES = 'US_LOS_ANGELES';
export const US_NEW_YORK_CITY = 'US_NEW_YORK_CITY';
export const US_PHILADELPHIA = 'US_PHILADELPHIA';
export const US_SAN_DIEGO = 'US_SAN_DIEGO';