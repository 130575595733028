'use es6';

import { combineReducers } from 'redux';
import accreditations from './accreditations';
import app from './app';
import auth from './auth';
import awards from './awards';
import certifications from './certifications';
import { integrationsReducer } from '../slices/integrationsSlice';
import notifications from 'ReduxMessenger/reducers/Notifications';
import profile from './profile';
import storefront from './storefront';
import { servicesReducer } from '../slices/serviceCatalogSlice';
export default combineReducers({
  accreditations,
  app,
  auth,
  awards,
  certifications,
  integrations: integrationsReducer,
  notifications,
  profile,
  storefront,
  services: servicesReducer
});