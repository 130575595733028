// Ranges are from https://git.hubteam.com/HubSpot/signup_ui/blob/master/signup_ui/static/js/routeHandlers/UserRegistration/academy/academyAnswerMapping.js

export const RANGE_1 = 'RANGE_1';
export const RANGE_2_5 = 'RANGE_2_5';
export const RANGE_6_10 = 'RANGE_6_10';
export const RANGE_11_25 = 'RANGE_11_25';
export const RANGE_26_50 = 'RANGE_26_50';
export const RANGE_51_200 = 'RANGE_51_200';
export const RANGE_201_1000 = 'RANGE_201_1000';
export const RANGE_1001_10000 = 'RANGE_1001_10000';
export const RANGE_10001_ = 'RANGE_10001_';
export const PREFER_NOT_TO_SAY = 'PREFER_NOT_TO_SAY';