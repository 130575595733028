import getLang from 'I18n/utils/getLang';
import { apiClientProvider } from '../utils/apiClient';
let marketplaceSearchBase = 'marketplace-search/v1';
export const setMarketplaceSearchBase = base => {
  marketplaceSearchBase = base;
};
export const marketplaceSearchProfiles = apiClientProvider((apiClient, query) => {
  return apiClient.post(`${marketplaceSearchBase}/profiles/search`, {
    data: [Object.assign({}, query, {
      callingUserLanguage: getLang()
    })]
  });
});
export const marketplaceSearchProfilesMulti = apiClientProvider((apiClient, queries) => {
  return apiClient.post(`${marketplaceSearchBase}/profiles/search`, {
    data: queries
  });
});