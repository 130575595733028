/**
 * A Schema<T> can be used to validate whether a runtime value matches the type T.
 * Typical usage looks like:
 *
 * ```
 * const usableValue = t.assert(schema, unsafeValue);
 * ```
 *
 * `usableValue` will be properly typed, validation will be skipped in
 * production, and the assert call will throw in development if the schema
 * is not satisfied.
 */

export function rawExecuteSchema(ctx, schema, value) {
  // extracting the stack from call machinery. This code should be as fast
  // as we can make it, so avoiding heap and frame allocations is
  // beneficial.
  const stack = [{
    schema,
    value,
    parent: undefined,
    key: undefined
  }];
  let validating;
  function pushValidation(_schema, _value, key) {
    stack.push({
      schema: _schema,
      value: _value,
      parent: validating,
      key
    });
    return;
  }
  let violations = 0;
  while (stack.length) {
    validating = stack.pop();
    const nextSchema = validating.schema;
    const nextValue = validating.value;
    if (!nextSchema.validate(nextValue, pushValidation, ctx)) {
      if (ctx.quick) {
        return {
          type: 'fail',
          schemaViolations: ctx.schemaViolations
        };
      } else {
        violations += 1;
        ctx.schemaViolations.push(validating);
      }
    }
  }
  if (violations > 0) {
    return {
      type: 'fail',
      schemaViolations: ctx.schemaViolations
    };
  } else {
    return {
      type: 'pass',
      value: value
    };
  }
}
export function validate(schema, value) {
  return rawExecuteSchema({
    quick: true,
    schemaViolations: []
  }, schema, value).type === 'pass';
}
export function assert(schema, value) {
  if (process.env.NODE_ENV === 'production') {
    return value;
  } else {
    const result = rawExecuteSchema({
      quick: false,
      schemaViolations: []
    }, schema, value);
    if (result.type === 'fail') {
      for (const violation of result.schemaViolations) {
        const keyPath = [];
        let parentViolation = violation;
        while (parentViolation !== undefined) {
          if (parentViolation.key !== undefined) {
            keyPath.push(typeof parentViolation.key === 'string' ?
            // TODO: strictly speaking string keys may need to be escaped
            `.${parentViolation.key}` : `[${parentViolation.key}]`);
          }
          parentViolation = parentViolation.parent;
        }
        console.error(`[<root>${keyPath.reverse().join('')}] ts-schema expected: ${violation.schema.getDescription()} but received`, typeof violation.value === 'string' ? `'${violation.value}'` : violation.value);
      }
      throw new Error(`${result.schemaViolations.length} schema violation(s) detected`);
    } else {
      return result.value;
    }
  }
}