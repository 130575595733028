import { createSlice } from '@reduxjs/toolkit';
import { fromFilterStateToLocation, fromLocationToFilterState } from '../utils/storefrontFilters';
/* @ts-expect-error module is untyped */
import { push } from '../utils/history';
/* @ts-expect-error module is untyped */
import { FILTER_TAG_KEYS } from '../constants/search/Filters';
import * as FilterKeys from '../constants/search/FilterKey';
import * as SortField from '../constants/search/SortField';
import { US } from 'marketplace-ui-common/constants/Country';
import { storefrontFiltersInitialState } from '../constants/StorefrontFilters';
export const DEFAULT_SORT = SortField.PARTNER_TIER_DESC;
const storefrontFiltersSlice = createSlice({
  name: 'storefront/filters',
  initialState: storefrontFiltersInitialState,
  reducers: {
    update(state, action) {
      const {
        updates,
        catalogServices // passed via middleware/filters.js
      } = action.payload;
      if (!Object.keys(updates).includes(FilterKeys.OFFSET)) {
        updates[FilterKeys.OFFSET] = 0;
      }
      const newFilterState = Object.assign({}, state, updates);
      // if country is not US reset states
      if (newFilterState[FilterKeys.COUNTRY] !== US) {
        newFilterState[FilterKeys.STATE] = storefrontFiltersInitialState[FilterKeys.STATE];
      }
      push(fromFilterStateToLocation(newFilterState, catalogServices));
      return newFilterState;
    },
    clearFilters(state) {
      const sort = state.sorts === SortField.RELEVANCE_DESC ? DEFAULT_SORT : state.sorts;
      const newFilterState = Object.assign({}, state, FILTER_TAG_KEYS.reduce((result, filterKey) => Object.assign({}, result, {
        [filterKey]: storefrontFiltersInitialState[filterKey]
      }), {}), {
        [FilterKeys.SORTS]: sort
      });
      newFilterState[FilterKeys.OFFSET] = 0;
      push(fromFilterStateToLocation(newFilterState, []));
      return newFilterState;
    },
    updateFromLocation(state, action) {
      const {
        location,
        params,
        catalogServices // passed via middleware/filter.js
      } = action.payload;
      const updates = fromLocationToFilterState(location, params, catalogServices);
      return Object.assign({}, storefrontFiltersInitialState, updates);
    }
  }
});
export const storefrontFiltersReducer = storefrontFiltersSlice.reducer;
export const updateStorefrontFilters = storefrontFiltersSlice.actions.update;
export const clearStorefrontFilters = storefrontFiltersSlice.actions.clearFilters;
export const updateStorefrontFiltersFromLocation = storefrontFiltersSlice.actions.updateFromLocation;
export const getMultiSelectStorefrontFilters = state => state.storefront.multiSelectFilters;