/**
 * @deprecated use marketplace-ui-common
 */
export const UNINITIALIZED = 'UNINITIALIZED';
/**
 * @deprecated use marketplace-ui-common
 */
export const PENDING = 'PENDING';
/**
 * @deprecated use marketplace-ui-common
 */
export const SUCCEEDED = 'SUCCEEDED';
/**
 * @deprecated use marketplace-ui-common
 */
export const FAILED = 'FAILED';