'use es6';

import { combineReducers } from 'redux';
import Immutable from 'immutable';
import * as RequestState from 'marketplace-ui-common/requestStates/RequestState';
import * as ActionTypes from '../../actions/ActionTypes';
const EMPTY_LIST = Immutable.List();
const requestState = (state = RequestState.UNINITIALIZED, action) => {
  switch (action.type) {
    case ActionTypes.STOREFRONT_PROFILES_REQUESTED:
      return RequestState.PENDING;
    case ActionTypes.STOREFRONT_PROFILES_RECEIVED:
      return RequestState.SUCCEEDED;
    case ActionTypes.STOREFRONT_PROFILES_FAILED:
      return RequestState.FAILED;
    default:
      return state;
  }
};
const results = (state = EMPTY_LIST, action) => {
  switch (action.type) {
    case ActionTypes.STOREFRONT_PROFILES_RECEIVED:
      return action.payload;
    default:
      return state;
  }
};
const total = (state = 0, action) => {
  switch (action.type) {
    case ActionTypes.STOREFRONT_PROFILES_RECEIVED:
      return action.meta.total;
    case ActionTypes.STOREFRONT_PROFILES_REQUESTED:
    case ActionTypes.STOREFRONT_PROFILES_FAILED:
      return 0;
    default:
      return state;
  }
};
export default combineReducers({
  requestState,
  results,
  total
});