'use es6';

import I18n from 'I18n';
import unescapedText from 'I18n/utils/unescapedText';
import { getCountrySentenceI18nKey } from 'marketplace-ui-common/utils/i18n';
import * as FilterKeys from '../constants/search/FilterKey';
import { OfficeLocationType } from '../constants/search/Filters';
import * as SortField from '../constants/search/SortField';
const CURRENT_YEAR = new Date().getFullYear();
const baseCoreRouteKey = 'marketplace-ui-solutions-core';
const filterKeyToTranslationKeyMap = {
  [FilterKeys.SERVICE]: 'service',
  [FilterKeys.INDUSTRY]: 'industry',
  [FilterKeys.STATE]: 'state',
  [FilterKeys.LANGUAGE]: 'language',
  [FilterKeys.BUDGET]: 'budget',
  [FilterKeys.CERTIFICATION]: 'certification',
  [FilterKeys.TIER]: 'tier'
};
export const translateFilterValue = (filterKey, value, useTitleCase = false) => {
  const casing = useTitleCase ? 'title' : 'sentence';
  if (filterKey === FilterKeys.COUNTRY) {
    return unescapedText(`SharedI18nStrings.countryCodes.${value}`);
  }
  if (filterKey === FilterKeys.OFFICE_LOCATION) {
    if (value === OfficeLocationType.PHYSICAL) {
      return unescapedText('ecosystem-marketplace-solutions-ui.storefront.filters.office_location.options.physical');
    } else if (value === OfficeLocationType.REMOTE) {
      return unescapedText('ecosystem-marketplace-solutions-ui.storefront.filters.office_location.options.remote');
    } else if (value === OfficeLocationType.ANY) {
      return unescapedText('ecosystem-marketplace-solutions-ui.storefront.filters.office_location.options.any');
    } else {
      return '';
    }
  } else if (filterKey === FilterKeys.LANGUAGE) {
    return unescapedText(`SharedI18nStrings.languageNames.${value}`);
  } else if (filterKey === FilterKeys.INDUSTRY) {
    return unescapedText(`marketplace-ui-common.industry.${value}.${casing}`);
  }
  const translationKey = filterKeyToTranslationKeyMap[filterKey];
  return unescapedText(`${baseCoreRouteKey}.${translationKey}.${value}.${casing}`);
};
const getFiltersText = (filters, catalogServices, baseKey, defaultKey = '', opts = {}, useTitleCase = false) => {
  const {
    [FilterKeys.SERVICE]: service,
    [FilterKeys.INDUSTRY]: industry,
    [FilterKeys.COUNTRY]: country,
    [FilterKeys.STATE]: state
  } = filters;
  const textKeys = [];
  if (typeof service === 'string' ? service : service && service.length === 1) {
    textKeys.push('service');
    const catalogService = catalogServices.filter(catService => catService.id === service[0])[0];
    opts.service = catalogService ? catalogService.name : '';
  }
  if (typeof industry === 'string' ? industry : industry && industry.length === 1) {
    textKeys.push('industry');
    opts.industry = translateFilterValue(FilterKeys.INDUSTRY, industry, useTitleCase);
  }
  if (country) {
    // State takes precedent over country for location
    const locationTextKey = state && (state.length === 1 || !Array.isArray(state)) ? `${baseCoreRouteKey}.state.${state}.${useTitleCase ? 'title' : 'sentence'}` : getCountrySentenceI18nKey(country);
    textKeys.push('location');
    opts.location = unescapedText(locationTextKey);
  }
  if (!textKeys.length) {
    if (!defaultKey) {
      return null;
    }
    textKeys.push(defaultKey);
  }
  return unescapedText(`${baseKey}.${textKeys.join('_')}`, opts);
};
export const getStorefrontResultsHeaderText = (filters, catalogServices) => {
  const baseResultsHeaderKey = 'ecosystem-marketplace-solutions-ui.storefront.results.header';
  if (filters[FilterKeys.SEARCH]) {
    return unescapedText(`${baseResultsHeaderKey}.search`);
  }
  return getFiltersText(filters, catalogServices, baseResultsHeaderKey, '', {}, true);
};
export const getStorefrontPageTitleText = (filters, catalogServices, searchResultsCount) => {
  return getFiltersText(filters, catalogServices, 'ecosystem-marketplace-solutions-ui.storefront.htmlTitle', 'default', {
    count: searchResultsCount
  }, true);
};
export const getStorefrontMetaDescription = (filters, catalogServices) => {
  return getFiltersText(filters, catalogServices, 'ecosystem-marketplace-solutions-ui.storefront.metaDescription', 'default', {
    year: `${CURRENT_YEAR}`
  }, true);
};
export const getProfilePageTitleText = (profileListing, i18nFn = unescapedText) => {
  const {
    name,
    reviewCount
  } = profileListing;
  const titleKey = reviewCount ? 'reviews' : 'default';
  return i18nFn(`ecosystem-marketplace-solutions-ui.profile.htmlTitle.${titleKey}`, {
    name: I18n.SafeString(name)
  });
};
export const getTranslatedSortText = sortKey => {
  let key = '';
  switch (sortKey) {
    case SortField.PARTNER_TIER_DESC:
      key = 'partnerTier';
      break;
    case SortField.NEWEST:
      key = 'newestAdded';
      break;
    case SortField.NAME_A_Z:
      key = 'nameDesc';
      break;
    case SortField.NAME_Z_A:
      key = 'nameAsc';
      break;
    case SortField.RELEVANCE_DESC:
      key = 'relevance';
      break;
    default:
  }
  return I18n.text(`ecosystem-marketplace-solutions-ui.storefront.results.sort.${key}`);
};
export const getCatalogServiceTrackingName = (serviceId, catalogServices) => {
  const serviceObj = catalogServices.find(service => service.id === serviceId);
  return serviceObj && serviceObj.englishName ? serviceObj.englishName : serviceId;
};