export function buildGetCachedSearchValues(key) {
  return function () {
    try {
      const parsedItem = JSON.parse(localStorage.getItem(key) || '[]');
      return Array.isArray(parsedItem) ? parsedItem : [];
    } catch (e) {
      return [];
    }
  };
}
export function buildSetCachedSearchValue(key) {
  return function (searchValue, cachedSearchValues) {
    if (cachedSearchValues.includes(searchValue)) {
      return;
    }
    try {
      localStorage.setItem(key, JSON.stringify([searchValue, ...cachedSearchValues].slice(0, 4)));
    } catch (e) {
      return;
    }
  };
}