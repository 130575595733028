import { useLocation } from 'react-router-dom';
import getLang from 'I18n/utils/getLang';
// @ts-expect-error dependency missing types
import { getFullSocialImageUrl } from '../utils/urls';
export function useSocialScreenshotUrl() {
  const lang = getLang();
  const {
    pathname
  } = useLocation();
  return getFullSocialImageUrl(lang, pathname);
}