import { useContext, useEffect } from 'react';
import { usePrevious } from '../../hooks/usePrevious';
import { HSAnalyticsContext } from '../context/HSAnalyticsContext';
export default function HSPageViewTracker({
  overrideTrack = false,
  pathname
}) {
  const prevPathname = usePrevious(pathname);
  const {
    initialPageLoad,
    setInitialPageLoad
  } = useContext(HSAnalyticsContext);
  useEffect(() => {
    if ((!initialPageLoad || overrideTrack) && pathname !== prevPathname) {
      const _hsq = window._hsq = window._hsq || [];
      _hsq.push(['setPath', pathname]);
      _hsq.push(['trackPageView']);
    }
    setInitialPageLoad(false);
  }, [initialPageLoad, overrideTrack, pathname, prevPathname, setInitialPageLoad]);
  return null;
}