import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as RequestState from 'marketplace-ui-common/requestStates/RequestState';
import { MARKETPLACE_SERVICE_CATEGORIES, MARKETPLACE_QA_SERVICE_CATEGORIES } from 'marketplace-ui-common/services/ServiceCatalogTypes';
/* @ts-expect-error module is untyped */
import { maybeEarlyRequest } from '../utils/earlyRequests';
import { fetchEnglishServices, fetchServices } from '../clients/serviceCatalogClient';
/* @ts-expect-error module is untyped */
import { isQa } from '../utils/env';
const SERVICE_CATEGORIES = isQa() ? MARKETPLACE_QA_SERVICE_CATEGORIES : MARKETPLACE_SERVICE_CATEGORIES;
export const servicesInitialState = {
  services: [],
  servicesRequestState: RequestState.UNINITIALIZED
};
export const fetchActiveServicesThunk = createAsyncThunk('serviceCatalog/fetchServices', () => {
  return maybeEarlyRequest('services', fetchServices).then(resp => maybeEarlyRequest('services-english', fetchEnglishServices).then(resp2 => resp.map((service, index) => Object.assign({}, service, {
    englishName: resp2[index].name
  }))));
});
const servicesSlice = createSlice({
  name: 'serviceCatalog/services',
  initialState: servicesInitialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchActiveServicesThunk.pending, state => {
      state.servicesRequestState = RequestState.PENDING;
    });
    builder.addCase(fetchActiveServicesThunk.fulfilled, (state, action) => {
      state.servicesRequestState = RequestState.SUCCEEDED;
      state.services = action.payload.filter(service => SERVICE_CATEGORIES.includes(service.categoryId));
    });
    builder.addCase(fetchActiveServicesThunk.rejected, state => {
      state.servicesRequestState = RequestState.FAILED;
    });
  }
});
export const servicesReducer = servicesSlice.reducer;
export const getServices = state => state.services.services;
export const getServicesRequestState = state => state.services.servicesRequestState;