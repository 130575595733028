module.exports = {
  "/": {
    "name": "home",
    "success": {
      "homepage loaded": [
        "HOMEPAGE_LOAD"
      ],
      "storefront loaded": [
        "STOREFRONT_LOAD"
      ],
      "storefront 404": [
        "STOREFRONT_404_LOAD"
      ]
    },
    "error": [
      "HOMEPAGE_ERROR_LOAD",
      "STOREFRONT_ERROR_LOAD"
    ]
  },
  "/all": {
    "name": "storefront-index",
    "success": {
      "storefront loaded": [
        "STOREFRONT_LOAD"
      ],
      "storefront 404": [
        "STOREFRONT_404_LOAD"
      ]
    },
    "error": [
      "STOREFRONT_ERROR_LOAD"
    ]
  },
  "/_preview": {
    "name": "profile-preview",
    "success": {
      "profile loaded": [
        "PROFILE_LOAD"
      ]
    },
    "error": [
      "PROFILE_ERROR_LOAD"
    ]
  },
  "/:superCategory": {
    "name": "super-category",
    "success": {
      "storefront loaded": [
        "STOREFRONT_LOAD"
      ],
      "storefront 404": [
        "STOREFRONT_404_LOAD"
      ],
      "city collection loaded": [
        "CITY_COLLECTION_LOAD"
      ],
      "profile loaded": [
        "PROFILE_LOAD"
      ],
      "profile 404": [
        "PROFILE_404_LOAD"
      ]
    },
    "error": [
      "STOREFRONT_ERROR_LOAD",
      "PROFILE_ERROR_LOAD",
      "CITY_COLLECTION_ERROR"
    ]
  },
  "/:profileSlug/write-review": {
    "name": "profile-write-review",
    "success": {
      "profile review loaded": [
        "PROFILE_LOAD",
        "REVIEW_WIZARD_LOAD"
      ],
      "profile 404": [
        "PROFILE_404_LOAD"
      ]
    },
    "error": [
      "PROFILE_ERROR_LOAD"
    ]
  },
  "/:superCategory/:category": {
    "name": "storefront-category",
    "success": {
      "storefront loaded": [
        "STOREFRONT_LOAD"
      ],
      "storefront 404": [
        "STOREFRONT_404_LOAD"
      ]
    },
    "error": [
      "STOREFRONT_ERROR_LOAD"
    ]
  },
  "/:superCategory/:category/:subcategory": {
    "name": "storefront-subcategory",
    "success": {
      "storefront loaded": [
        "STOREFRONT_LOAD"
      ],
      "storefront 404": [
        "STOREFRONT_404_LOAD"
      ]
    },
    "error": [
      "STOREFRONT_ERROR_LOAD"
    ]
  },
  "/:superCategory/:category/:subcategory/:subcategory2": {
    "name": "storefront-subcategory2",
    "success": {
      "storefront loaded": [
        "STOREFRONT_LOAD"
      ],
      "storefront 404": [
        "STOREFRONT_404_LOAD"
      ]
    },
    "error": [
      "STOREFRONT_ERROR_LOAD"
    ]
  }
};