'use es6';

import { combineReducers } from 'redux';
import * as ActionTypes from '../actions/ActionTypes';
const appContext = (state = null, action) => {
  switch (action.type) {
    case ActionTypes.APP_CONTEXT_SET:
      return action.payload;
    default:
      return state;
  }
};
const trackingParams = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.APP_STARTED:
      return action.payload.trackingParams;
    default:
      return state;
  }
};
export default combineReducers({
  appContext,
  trackingParams
});