// @ts-expect-error untyped import
import { CREDENTIALS } from '../constants/ProfileKeys';

// TODO: define credentials in a core.
// Define certifications awards and accreditations as subtypes of credentials
export const getCredentialById = (credentials, id) => {
  const normalizedId = typeof id !== 'number' ? parseInt(id, 10) : id;
  return credentials.find(credential => credential.id === normalizedId);
};
export const getIsProfileListingAccredited = (accreditations, profileListing) => {
  if (!accreditations || !profileListing) {
    return null;
  }
  return accreditations.some(accred => profileListing[CREDENTIALS].includes(accred.id));
};