'use es6';

import AbstractPermissionComponent from './AbstractPermissionComponent';
import { connect } from 'react-redux';
export default (valueSelector => {
  const mapStateToProps = (state, ownProps) => {
    const {
      disjunct = false,
      names
    } = ownProps;
    return {
      permission: names ? Boolean(names && names.reduce((permission, permissionName) => {
        if (disjunct) {
          return permission || valueSelector(state, {
            name: permissionName
          });
        }
        return permission && valueSelector(state, {
          name: permissionName
        });
      }, !disjunct)) : valueSelector(state, ownProps)
    };
  };
  return connect(mapStateToProps)(AbstractPermissionComponent);
});