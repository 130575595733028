// Note: these must match translation keys marketplace-ui-reviews.responses
/**
 * @deprecated Please use string with ReviewQuestion type
 */
export const TITLE = 'TITLE';
/**
 * @deprecated Please use string with ReviewQuestion type
 */
export const REVIEW = 'REVIEW';
/**
 * @deprecated Please use string with ReviewQuestion type
 */
export const FOR = 'FOR';
/**
 * @deprecated Please use string with ReviewQuestion type
 */
export const PROS = 'PROS';
/**
 * @deprecated Please use string with ReviewQuestion type
 */
export const CONS = 'CONS';
/**
 * @deprecated Please use string with ReviewQuestion type
 */
export const CUSTOMIZABLE = 'CUSTOMIZABLE'; // Asset marketplace only
/**
 * @deprecated Please use string with ReviewQuestion type
 */
export const SERVICES = 'CATALOG_SERVICES'; // Solutions marketplace only
/**
 * @deprecated Please use string with ReviewQuestion type
 */
export const FEEDBACK = 'FEEDBACK';
export const QuestionOrder = ['TITLE', 'REVIEW', 'FOR', 'CUSTOMIZABLE', 'PROS', 'CONS'];
export const TagOrder = ['CATALOG_SERVICES'];