'use es6';

import { fetchProfileReviewSummary } from '../clients/ecosystemClient';
import { fetchProfileListing, fetchProfileListingPreview } from '../clients/profilesClient';
import * as ActionTypes from './ActionTypes';
export const getProfileReviewSummary = profileId => dispatch => {
  dispatch({
    type: ActionTypes.PROFILE_REVIEW_SUMMARY_REQUESTED
  });
  return fetchProfileReviewSummary(profileId).then(resp => {
    const reviewSummary = resp.reviewSummaries[0].reviewSummary;
    dispatch({
      type: ActionTypes.PROFILE_REVIEW_SUMMARY_RECEIVED,
      payload: reviewSummary
    });
  }).catch(error => {
    dispatch({
      type: ActionTypes.PROFILE_REVIEW_SUMMARY_FAILED,
      payload: error
    });
  });
};
export const getProfileListing = (slug, isPreview) => dispatch => {
  dispatch({
    type: ActionTypes.PROFILE_LISTING_REQUESTED,
    meta: {
      slug
    }
  });
  const request = isPreview ? fetchProfileListingPreview() : fetchProfileListing(slug);
  return request.then(resp => {
    dispatch({
      type: ActionTypes.PROFILE_LISTING_RECEIVED,
      payload: resp,
      meta: {
        slug
      }
    });
    dispatch(getProfileReviewSummary(resp.id));
    return resp;
  }).catch(error => {
    dispatch({
      type: ActionTypes.PROFILE_LISTING_FAILED,
      payload: error,
      meta: {
        slug
      }
    });
  });
};
export const clearProfileListing = () => dispatch => {
  dispatch({
    type: ActionTypes.CLEAR_PROFILE_LISTING
  });
};