'use es6';

import events from '../../events.yaml';
import * as FilterKey from '../constants/search/FilterKey';
import { getCatalogServiceTrackingName } from './i18n';
export const getTrackerConfig = (inAppOrPublic = 'in-app') => ({
  events,
  properties: {
    namespace: 'marketplace',
    type: 'solution provider',
    inAppOrPublic
  }
});
export const getStorefrontFiltersEventProperties = (filters, catalogServices) => {
  const {
    [FilterKey.SERVICE]: service,
    [FilterKey.INDUSTRY]: industry,
    [FilterKey.COUNTRY]: country,
    [FilterKey.STATE]: state,
    [FilterKey.LANGUAGE]: language,
    [FilterKey.BUDGET]: budget,
    [FilterKey.CERTIFICATION]: certification,
    [FilterKey.TIER]: tier,
    [FilterKey.OFFICE_LOCATION]: location,
    [FilterKey.SORTS]: sorts,
    [FilterKey.SEARCH]: search
  } = filters;
  const properties = {
    service: service.map(serviceId => getCatalogServiceTrackingName(serviceId, catalogServices)),
    industry,
    country,
    state,
    language,
    budget,
    certification,
    tier,
    location,
    sorts,
    search,
    searchLength: search ? search.length : undefined
  };
  Object.keys(properties).forEach(key => {
    if (!properties[key]) {
      delete properties[key];
    }
  });
  return properties;
};