import * as FilterKeys from './search/FilterKey';
import * as SortField from './search/SortField';
export const DEFAULT_SORT = SortField.PARTNER_TIER_DESC;
export const storefrontFiltersInitialState = {
  [FilterKeys.SERVICE]: [],
  [FilterKeys.INDUSTRY]: [],
  [FilterKeys.COUNTRY]: '',
  [FilterKeys.STATE]: [],
  [FilterKeys.LANGUAGE]: [],
  [FilterKeys.BUDGET]: [],
  [FilterKeys.CERTIFICATION]: [],
  [FilterKeys.CREDENTIAL]: [],
  [FilterKeys.TIER]: [],
  [FilterKeys.OFFICE_LOCATION]: '',
  [FilterKeys.ACCREDITATION]: [],
  [FilterKeys.INTEGRATION]: [],
  [FilterKeys.SEARCH]: '',
  [FilterKeys.SORTS]: DEFAULT_SORT,
  [FilterKeys.OFFSET]: 0
};