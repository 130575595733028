export const SERVICE = 'service';
export const INDUSTRY = 'industry';
export const COUNTRY = 'country';
export const STATE = 'state';
export const LANGUAGE = 'language';
export const BUDGET = 'budget';
export const CERTIFICATION = 'certification';
export const CREDENTIAL = 'credential';
export const TIER = 'agency_tier';
export const OFFICE_LOCATION = 'office_location';
export const ACCREDITATION = 'accreditation';
export const INTEGRATION = 'integration';
export const SEARCH = 'search';
export const SORTS = 'sorts';
export const OFFSET = 'offset';