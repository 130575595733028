'use es6';

import { APP_STARTED, APP_CONTEXT_SET } from './ActionTypes';
import { getParsedTrackingParams } from 'ecosystem-marketplace-core/utils/trackingParams';
export const startApp = () => dispatch => {
  dispatch({
    type: APP_STARTED,
    payload: {
      trackingParams: getParsedTrackingParams()
    }
  });
};
export const setAppContext = appContext => dispatch => {
  dispatch({
    type: APP_CONTEXT_SET,
    payload: appContext
  });
};