import Raven from 'raven-js';
import isEqual from 'hs-lodash/isEqual';
import hasIn from 'transmute/hasIn';
import getIn from 'immutable-less/transmute/getIn';
import setIn from 'immutable-less/transmute/setIn';
import updateIn from 'immutable-less/transmute/updateIn';
import { produce } from 'immutable-less/internal/produce';
import { uniqueIdForObject } from 'immutable-less/utils/uniqueIdForObject';
import { ImmutableJSRecordMethodNames } from 'immutable-less/constants/ImmutableJSRecordMethodNames';
function bindOptionalContext(predicate, context) {
  if (!context) {
    return predicate;
  } else {
    return predicate.bind(context);
  }
}
export class BaseImmutableObject {
  equals(that) {
    return isEqual(this, that);
  }
  every(predicate, context) {
    const entries = Object.entries(this);
    const boundPredicate = bindOptionalContext(predicate, context);
    return entries.every(([key, value]) => boundPredicate(value, key, this));
  }

  // flatten() has no effect on Records
  flatten(__depth) {}
  forEach(predicate, context) {
    const entries = Object.entries(this);
    const boundPredicate = bindOptionalContext(predicate, context);
    let numIterations = 0;
    entries.every(([key, value]) => {
      numIterations += 1;
      return boundPredicate(value, key, this) !== false;
    });
    return numIterations;
  }
  get(key, notSetValue) {
    if (notSetValue !== undefined && !this.has(key)) {
      return notSetValue;
    } else {
      return this[key];
    }
  }
  getIn(keyPath, notSetValue) {
    if (notSetValue !== undefined && !this.hasIn(keyPath)) {
      return notSetValue;
    } else {
      return getIn(keyPath, this);
    }
  }
  has(key) {
    return Object.hasOwnProperty.call(this, key);
  }
  hasIn(keyPath) {
    return hasIn(keyPath, this);
  }
  hashCode() {
    return uniqueIdForObject(this);
  }
  map(mapper, context) {
    const entries = Object.entries(this);
    const boundMapper = bindOptionalContext(mapper, context);
    entries.forEach(([key, value]) => boundMapper(value, key, this));
  }
  reduce(reducer, initialReduction, context) {
    const entries = Object.entries(this);
    const boundReducer = bindOptionalContext(reducer, context);
    if (arguments.length < 2) {
      return entries.reduce(([, reduction], [key, value]) => [key, boundReducer(reduction, value, key, this)])[1];
    } else {
      return entries.reduce((reduction, [key, value]) => boundReducer(reduction, value, key, this), initialReduction);
    }
  }
  set(key, value) {
    if (this.has(key)) {
      return produce(this, draft => {
        draft[key] = value;
      });
    } else {
      return this;
    }
  }
  setIn(keyPath, value) {
    return setIn(keyPath, value, this);
  }
  some(predicate, context) {
    const entries = Object.entries(this);
    const boundPredicate = bindOptionalContext(predicate, context);
    return entries.some(([key, value]) => boundPredicate(value, key, this));
  }

  // sortBy() has no effect on Records
  sortBy(__comparatorValueMapper, __comparator) {}
  update(...args) {
    if (args.length === 1) {
      const [updater] = args;
      return produce(this, updater);
    } else {
      let key;
      let updater;
      let currentValue;
      if (args.length === 2) {
        [key, updater] = args;
        currentValue = this[key];
      } else {
        [key, currentValue, updater] = args;
        if (this.has(key)) {
          currentValue = this[key];
        }
      }
      return this.set(key, updater(currentValue));
    }
  }
  updateIn(...args) {
    if (args.length === 2) {
      const [keyPath, updater] = args;
      return updateIn(keyPath, updater, this);
    } else {
      const [keyPath, notSetValue, updater] = args;
      if (!this.hasIn(keyPath)) {
        return updateIn(keyPath, () => updater(notSetValue), this);
      } else {
        return updateIn(keyPath, updater, this);
      }
    }
  }
  toJS() {
    return this.toJSON();
  }
  withMutations(mutator) {
    return produce(this, mutator);
  }
}

// strips the `abstract` modifiers from the methods while preserving the `this` type

ImmutableJSRecordMethodNames.forEach(methodName => {
  if (!Object.prototype.hasOwnProperty.call(BaseImmutableObject.prototype, methodName)) {
    // @ts-expect-error normally these would be tacked on to BaseImmutableObject's
    // type but this fallback is specifcally for code which is not typed
    BaseImmutableObject.prototype[methodName] = function () {
      const error = new Error(`Attempted to call unimplemented method '${methodName}()' on an ImmutableObject`);
      Raven.captureException(error);
    };
  }
});