'use es6';

import { stringify as stringifyParams } from 'hub-http/helpers/params';
import { getFullUrl } from 'hubspot-url-utils';
import { na1 } from 'hubspot-url-utils/hublets';
import getLang from 'I18n/utils/getLang';
import PortalIdParser from 'PortalIdParser';
import { Flow } from 'signup-constants/Flow';
import { SignupUrlGenerator } from 'signup-ui-url-generator/SignupUrlGenerator';
import * as AppContext from '../constants/AppContext';
const ROOT_URL = '/marketplace/solutions';
export const getAppRootUrl = () => {
  return `/ecosystem/${PortalIdParser.get()}${ROOT_URL}`;
};
export const getPublicRootUrl = lang => {
  switch (lang) {
    case 'pt':
    case 'pt-br':
      return `/pt${ROOT_URL}`;
    case 'ja':
    case 'jp':
      return `/ja${ROOT_URL}`;
    case 'zh-tw':
    case 'zh':
      return `/zh${ROOT_URL}`;
    case 'no-no':
    case 'no':
      return `/no${ROOT_URL}`;
    case 'de':
    case 'es':
    case 'fr':
    case 'nl':
    case 'it':
    case 'fi':
    case 'da':
    case 'pl':
    case 'sv':
      return `/${lang}${ROOT_URL}`;
    default:
      {
        return ROOT_URL;
      }
  }
};
export const getAppContextFromPathname = pathname => {
  if (
  // matches ecosystem.hubspot.com/marketplace/solutions
  /^\/marketplace\/solutions/.test(pathname) ||
  // matches ecosystem.hubspot.com/es/marketplace/solutions
  /^\/\w+\/marketplace\/solutions/.test(pathname)) {
    return AppContext.PUBLIC;
  } else if (/^\/ecosystem\/\d+/.test(pathname)) {
    return AppContext.IN_APP;
  }
  return null;
};
const getAppContextBasename = (appContext, lang) => {
  switch (appContext) {
    case AppContext.PUBLIC:
      return getPublicRootUrl(lang);
    case AppContext.IN_APP:
      return getAppRootUrl();
    default:
      return ROOT_URL;
  }
};
export const getFullLocationPathname = (pathname, appContext, lang) => {
  if (pathname && !pathname.startsWith('/')) {
    pathname = `/${pathname}`;
  }
  const basename = getAppContextBasename(appContext, lang);
  return `${basename}${pathname.replace(/\/?$/, '')}`;
};
export const getFullLocationUrl = ({
  pathname = '',
  search = ''
}, appContext, lang) => {
  if (!appContext) {
    appContext = getAppContextFromPathname(window.location.pathname);
  }
  if (!lang) {
    lang = getLang();
  }
  if (search === '?') {
    search = '';
  }
  let origin;
  if (appContext) {
    origin = appContext === AppContext.PUBLIC ? getFullUrl('ecosystem', {
      hubletOverride: na1
    }) : getFullUrl('app');
  } else {
    origin = window.location.origin;
  }
  const fullPathname = getFullLocationPathname(pathname, appContext, lang);
  return `${origin}${fullPathname}${search}`;
};
export const getPagePathname = (pathname, page) => {
  const replacement = page && page > 1 ? `/page/${page}` : '';
  return pathname.replace(/(\/?(page\/\d+)?)$/, replacement);
};
export const getSignUpUrl = (pathname = '') => {
  const signupLinkQuery = {};
  const hubsSignUpUrl = getFullLocationUrl({
    pathname
  }, AppContext.PUBLIC).replace(/^http(s)?:\/\//, '');
  signupLinkQuery['hubs_signup-url'] = hubsSignUpUrl;
  signupLinkQuery['hubs_signup-cta'] = 'partners-directory-apply';
  return new SignupUrlGenerator(Flow.DirectoryListing, {
    query: signupLinkQuery
  }).getUrl();
};
export const getAppShortlink = (pathname, params = {}) => {
  return `${getFullUrl('app', {
    hubletOverride: na1
  })}/l/ecosystem${ROOT_URL}${pathname}${Object.keys(params).length ? `?${stringifyParams(params)}` : ''}`;
};
export const getInAppReviewLink = (slug, search = '') => {
  return `${getFullUrl('app', {
    hubletOverride: na1
  })}/l/ecosystem/${ROOT_URL}/${slug}/write-review${search}`;
};
export const getEditProfileLink = portalId => {
  return `${getFullUrl('app')}/marketplace-providers/${portalId}/profile/company`;
};
export const getGoogleMapsUrl = address => {
  return `https://www.google.com/maps/search/?api=1&query=${address.replaceAll(',', '%2C').replaceAll(' ', '+')}`;
};
export const getFullSocialImageUrl = (lang, path) => {
  return `${getFullUrl('api', {
    hubletOverride: 'na1'
  })}/marketplace/profiles/public/v1/screenshots/profiles/${lang}/marketplace/solutions${path.replace(/\/$/, '')}.png`;
};