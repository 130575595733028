'use es6';

import { parse as parseParams } from 'hub-http/helpers/params';
const TRACKING_PARAMS = ['utm_medium', 'utm_source', 'utm_campaign', 'utm_id', 'utm_content', 'hubs_signup-url', 'hubs_signup-cta'];
export const getParsedTrackingParams = () => {
  if (window && window.location) {
    const params = parseParams(window.location.search.slice(1));
    return Object.keys(params).reduce((final, key) => {
      if (TRACKING_PARAMS.includes(key)) {
        final[key] = params[key];
      }
      return final;
    }, {});
  }
  return {};
};