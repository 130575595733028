'use es6';

import * as FilterKeys from '../constants/search/FilterKey';
import { OfficeLocationType } from '../constants/search/Filters';
import * as Industries from 'marketplace-ui-solutions-core/constants/routes/IndustryRoute';
import * as Countries from 'marketplace-ui-solutions-core/constants/routes/CountryRoute';
import * as States from 'marketplace-ui-solutions-core/constants/routes/USStateRoute';
import { SOLUTIONS_FILTERS_LANGUAGES } from 'marketplace-ui-common/constants/Languages';
import * as Budgets from 'marketplace-ui-solutions-core/constants/routes/BudgetRoute';
import * as Tiers from 'marketplace-ui-solutions-core/constants/routes/TierRoute';
const officeLocationTypeValuesMap = Object.keys(OfficeLocationType).reduce((final, key) => Object.assign({}, final, {
  [key]: OfficeLocationType[key].toLowerCase()
}), {});
const filterKeyToValueMap = {
  [FilterKeys.INDUSTRY]: Industries,
  [FilterKeys.COUNTRY]: Countries,
  [FilterKeys.STATE]: States,
  [FilterKeys.LANGUAGE]: SOLUTIONS_FILTERS_LANGUAGES.reduce((result, lang) => Object.assign({}, result, {
    [lang]: lang
  }), {}),
  [FilterKeys.BUDGET]: Budgets,
  [FilterKeys.TIER]: Tiers,
  [FilterKeys.OFFICE_LOCATION]: officeLocationTypeValuesMap
};
export const getSearchKeyFromPath = (filterKey, value, catalogServices = []) => {
  const valueMap = filterKeyToValueMap[filterKey];
  if (filterKey === FilterKeys.SERVICE) {
    const selectedService = catalogServices.filter(service => service.apiName === value)[0];
    return selectedService && selectedService.id;
  }
  return valueMap ? Object.keys(valueMap).find(key => valueMap[key] === value) : value;
};
export const getPathFromSearchKey = (filterKey, value, catalogServices = []) => {
  if (filterKey === FilterKeys.SERVICE) {
    const selectedService = catalogServices.filter(service => service.id === parseInt(value, 10))[0];
    return selectedService ? selectedService.apiName : value;
  }
  return filterKeyToValueMap[filterKey] && filterKeyToValueMap[filterKey][value] ? filterKeyToValueMap[filterKey][value] : value;
};