'use es6';

import { useEffect } from 'react';

/**
 * @deprecated moved to marketplace-ui-common/hooks
 */
export const useScrollEventListener = listener => {
  useEffect(() => {
    window.addEventListener('scroll', listener);
    return () => window.removeEventListener('scroll', listener);
  }, [listener]);
};