import * as RequestState from './RequestState';
export const isUninitialized = requestState => {
  return requestState === RequestState.UNINITIALIZED;
};
export const isPending = requestState => {
  return requestState === RequestState.PENDING;
};
export const isFulfilled = requestState => {
  return requestState === RequestState.SUCCEEDED || requestState === RequestState.FAILED;
};
export const isSucceeded = requestState => {
  return requestState === RequestState.SUCCEEDED;
};
export const isFailed = requestState => {
  return requestState === RequestState.FAILED;
};