/* hs-eslint ignored failing-rules */
/* eslint-disable no-prototype-builtins */

'use es6';

import { combineReducers } from 'redux';
import ReviewFilters from 'marketplace-ui-reviews/records/ReviewFilters';
import * as ReviewTypes from 'marketplace-ui-reviews/constants/ReviewType';
import { PAGE_KEY } from 'marketplace-ui-reviews/constants/ReviewsListQueries';
import * as ActionTypes from '../../actions/ActionTypes';
const filters = (state = new ReviewFilters(), action) => {
  switch (action.type) {
    case ActionTypes.PROFILE_LISTING_RECEIVED:
      return new ReviewFilters({
        reviewTypes: [ReviewTypes.PROFILE],
        entityId: action.payload.id
      });
    case ActionTypes.PROFILE_REVIEW_FILTERS_UPDATED:
      {
        let newFilters = state.updateFilters(action.payload);
        if (!action.payload.hasOwnProperty(PAGE_KEY)) {
          newFilters = newFilters.updateFilters({
            [PAGE_KEY]: 1
          });
        }
        return newFilters;
      }
    case ActionTypes.PROFILE_REVIEW_FILTERS_RESET:
      return new ReviewFilters();
    default:
      return state;
  }
};
export default combineReducers({
  filters
});