'use es6';

import * as FilterKey from '../search/FilterKey';
const {
  SERVICE,
  INDUSTRY,
  COUNTRY,
  STATE,
  LANGUAGE,
  BUDGET,
  CERTIFICATION,
  CREDENTIAL,
  TIER,
  OFFICE_LOCATION,
  SEARCH,
  SORTS,
  OFFSET,
  ACCREDITATION,
  INTEGRATION
} = FilterKey;
export const DEFAULT_SEARCH_LIMIT = 45;
export const ECOSYSTEM_QUERY_PREFIX = 'eco_';
export const PATH_KEYS = [SERVICE, INDUSTRY, COUNTRY, STATE];
export const QUERY_KEYS = [SEARCH, LANGUAGE, BUDGET, CERTIFICATION, TIER, OFFICE_LOCATION, SORTS, ACCREDITATION, INTEGRATION];
export const FILTER_TAG_KEYS = [SERVICE, INDUSTRY, ACCREDITATION, OFFICE_LOCATION, COUNTRY, STATE, LANGUAGE, BUDGET, INTEGRATION, CERTIFICATION, TIER];
export const MULTI_SELECT_FILTER_KEYS = [SERVICE, INDUSTRY, ACCREDITATION, STATE, LANGUAGE, BUDGET, CERTIFICATION, TIER, INTEGRATION];
export const FilterKeyToApiQueryKey = {
  [SERVICE]: 'catalogServicesFilter',
  [INDUSTRY]: 'industriesFilter',
  [COUNTRY]: 'countriesFilter',
  [STATE]: 'usStatesFilter',
  [LANGUAGE]: 'languagesFilter',
  [BUDGET]: 'budgetsFilter',
  [CERTIFICATION]: 'certificationsFilter',
  [CREDENTIAL]: 'credentialsFilter',
  [ACCREDITATION]: 'credentialsFilter',
  [INTEGRATION]: 'supportedAppOfferingsFilter',
  [TIER]: 'partnerTiers',
  [OFFICE_LOCATION]: 'locationRemote',
  [SEARCH]: 'search',
  [SORTS]: 'sorts',
  [OFFSET]: 'offset'
};
export const OfficeLocationType = {
  ANY: '',
  PHYSICAL: 'PHYSICAL',
  REMOTE: 'REMOTE'
};