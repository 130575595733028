export const MARKETPLACE_SERVICE_CATEGORIES = [6,
// Marketing
4,
// Sales
3,
// Customer Success
2,
// Operations
5 // CMS
];
export const MARKETPLACE_QA_SERVICE_CATEGORIES = [9541,
// Marketing
9543,
// Sales
9544,
// Customer Success
9545,
// Operations
9542 // CMS
];