// Pulled from https://git.hubteam.com/HubSpot/ecosystem/blob/master/ecosystem-data/src/main/java/com/hubspot/ecosystem/models/reviews/reviews/Industry.java

export const ACCOUNTING = 'ACCOUNTING';
export const AIRLINES_AVIATION = 'AIRLINES_AVIATION';
export const ALTERNATIVE_DISPUTE_RESOLUTION = 'ALTERNATIVE_DISPUTE_RESOLUTION';
export const ALTERNATIVE_MEDICINE = 'ALTERNATIVE_MEDICINE';
export const ANIMATION = 'ANIMATION';
export const APPAREL_FASHION = 'APPAREL_FASHION';
export const ARCHITECTURE_PLANNING = 'ARCHITECTURE_PLANNING';
export const ARTS_CRAFTS = 'ARTS_CRAFTS';
export const AUTOMOTIVE = 'AUTOMOTIVE';
export const AVIATION_AEROSPACE = 'AVIATION_AEROSPACE';
export const BANKING = 'BANKING';
export const BIOTECHNOLOGY = 'BIOTECHNOLOGY';
export const BROADCAST_MEDIA = 'BROADCAST_MEDIA';
export const BUILDING_MATERIALS = 'BUILDING_MATERIALS';
export const BUSINESS_SUPPLIES_EQUIPMENT = 'BUSINESS_SUPPLIES_EQUIPMENT';
export const CAPITAL_MARKETS = 'CAPITAL_MARKETS';
export const CHEMICALS = 'CHEMICALS';
export const CIVIC_SOCIAL_ORGANIZATION = 'CIVIC_SOCIAL_ORGANIZATION';
export const CIVIL_ENGINEERING = 'CIVIL_ENGINEERING';
export const COMMERCIAL_REAL_ESTATE = 'COMMERCIAL_REAL_ESTATE';
export const COMPUTER_NETWORK_SECURITY = 'COMPUTER_NETWORK_SECURITY';
export const COMPUTER_GAMES = 'COMPUTER_GAMES';
export const COMPUTER_HARDWARE = 'COMPUTER_HARDWARE';
export const COMPUTER_NETWORKING = 'COMPUTER_NETWORKING';
export const COMPUTER_SOFTWARE = 'COMPUTER_SOFTWARE';
export const CONSTRUCTION = 'CONSTRUCTION';
export const CONSUMER_ELECTRONICS = 'CONSUMER_ELECTRONICS';
export const CONSUMER_GOODS = 'CONSUMER_GOODS';
export const CONSUMER_SERVICES = 'CONSUMER_SERVICES';
export const COSMETICS = 'COSMETICS';
export const DAIRY = 'DAIRY';
export const DEFENSE_SPACE = 'DEFENSE_SPACE';
export const DESIGN = 'DESIGN';
export const EDUCATION_MANAGEMENT = 'EDUCATION_MANAGEMENT';
export const E_LEARNING = 'E_LEARNING';
export const ELECTRICAL_ELECTRONIC_MANUFACTURING = 'ELECTRICAL_ELECTRONIC_MANUFACTURING';
export const ENTERTAINMENT = 'ENTERTAINMENT';
export const ENVIRONMENTAL_SERVICES = 'ENVIRONMENTAL_SERVICES';
export const EVENTS_SERVICES = 'EVENTS_SERVICES';
export const EXECUTIVE_OFFICE = 'EXECUTIVE_OFFICE';
export const FACILITIES_SERVICES = 'FACILITIES_SERVICES';
export const FARMING = 'FARMING';
export const FINANCIAL_SERVICES = 'FINANCIAL_SERVICES';
export const FINE_ART = 'FINE_ART';
export const FISHERY = 'FISHERY';
export const FOOD_BEVERAGES = 'FOOD_BEVERAGES';
export const FOOD_PRODUCTION = 'FOOD_PRODUCTION';
export const FUND_RAISING = 'FUND_RAISING';
export const FURNITURE = 'FURNITURE';
export const GAMBLING_CASINOS = 'GAMBLING_CASINOS';
export const GLASS_CERAMICS_CONCRETE = 'GLASS_CERAMICS_CONCRETE';
export const GOVERNMENT_ADMINISTRATION = 'GOVERNMENT_ADMINISTRATION';
export const GOVERNMENT_RELATIONS = 'GOVERNMENT_RELATIONS';
export const GRAPHIC_DESIGN = 'GRAPHIC_DESIGN';
export const HEALTH_WELLNESS_FITNESS = 'HEALTH_WELLNESS_FITNESS';
export const HIGHER_EDUCATION = 'HIGHER_EDUCATION';
export const HOSPITAL_HEALTH_CARE = 'HOSPITAL_HEALTH_CARE';
export const HOSPITALITY = 'HOSPITALITY';
export const HUMAN_RESOURCES = 'HUMAN_RESOURCES';
export const IMPORT_EXPORT = 'IMPORT_EXPORT';
export const INDIVIDUAL_FAMILY_SERVICES = 'INDIVIDUAL_FAMILY_SERVICES';
export const INDUSTRIAL_AUTOMATION = 'INDUSTRIAL_AUTOMATION';
export const INFORMATION_SERVICES = 'INFORMATION_SERVICES';
export const INFORMATION_TECHNOLOGY_SERVICES = 'INFORMATION_TECHNOLOGY_SERVICES';
export const INSURANCE = 'INSURANCE';
export const INTERNATIONAL_AFFAIRS = 'INTERNATIONAL_AFFAIRS';
export const INTERNATIONAL_TRADE_DEVELOPMENT = 'INTERNATIONAL_TRADE_DEVELOPMENT';
export const INVESTMENT_BANKING = 'INVESTMENT_BANKING';
export const INVESTMENT_MANAGEMENT = 'INVESTMENT_MANAGEMENT';
export const JUDICIARY = 'JUDICIARY';
export const LAW_ENFORCEMENT = 'LAW_ENFORCEMENT';
export const LAW_PRACTICE = 'LAW_PRACTICE';
export const LEGAL_SERVICES = 'LEGAL_SERVICES';
export const LEGISLATIVE_OFFICE = 'LEGISLATIVE_OFFICE';
export const LEISURE_TRAVEL_TOURISM = 'LEISURE_TRAVEL_TOURISM';
export const LIBRARIES = 'LIBRARIES';
export const LOGISTICS_SUPPLY_CHAIN = 'LOGISTICS_SUPPLY_CHAIN';
export const LUXURY_GOODS_JEWELRY = 'LUXURY_GOODS_JEWELRY';
export const MACHINERY = 'MACHINERY';
export const MANAGEMENT_CONSULTING = 'MANAGEMENT_CONSULTING';
export const MARITIME = 'MARITIME';
export const MARKET_RESEARCH = 'MARKET_RESEARCH';
export const MARKETING_ADVERTISING = 'MARKETING_ADVERTISING';
export const MECHANICAL_INDUSTRIAL_ENGINEERING = 'MECHANICAL_INDUSTRIAL_ENGINEERING';
export const MEDIA_PRODUCTION = 'MEDIA_PRODUCTION';
export const MEDICAL_DEVICES = 'MEDICAL_DEVICES';
export const MEDICAL_PRACTICE = 'MEDICAL_PRACTICE';
export const MENTAL_HEALTH_CARE = 'MENTAL_HEALTH_CARE';
export const MILITARY = 'MILITARY';
export const MINING_METALS = 'MINING_METALS';
export const MOTION_PICTURES_FILM = 'MOTION_PICTURES_FILM';
export const MUSEUMS_INSTITUTIONS = 'MUSEUMS_INSTITUTIONS';
export const MUSIC = 'MUSIC';
export const NANOTECHNOLOGY = 'NANOTECHNOLOGY';
export const NEWSPAPERS = 'NEWSPAPERS';
export const NON_PROFIT_ORGANIZATION_MANAGEMENT = 'NON_PROFIT_ORGANIZATION_MANAGEMENT';
export const OIL_ENERGY = 'OIL_ENERGY';
export const ONLINE_MEDIA = 'ONLINE_MEDIA';
export const OUTSOURCING_OFFSHORING = 'OUTSOURCING_OFFSHORING';
export const PACKAGE_FREIGHT_DELIVERY = 'PACKAGE_FREIGHT_DELIVERY';
export const PACKAGING_CONTAINERS = 'PACKAGING_CONTAINERS';
export const PAPER_FOREST_PRODUCTS = 'PAPER_FOREST_PRODUCTS';
export const PERFORMING_ARTS = 'PERFORMING_ARTS';
export const PHARMACEUTICALS = 'PHARMACEUTICALS';
export const PHILANTHROPY = 'PHILANTHROPY';
export const PHOTOGRAPHY = 'PHOTOGRAPHY';
export const PLASTICS = 'PLASTICS';
export const POLITICAL_ORGANIZATION = 'POLITICAL_ORGANIZATION';
export const PRIMARY_SECONDARY_EDUCATION = 'PRIMARY_SECONDARY_EDUCATION';
export const PRINTING = 'PRINTING';
export const PROFESSIONAL_TRAINING_COACHING = 'PROFESSIONAL_TRAINING_COACHING';
export const PROGRAM_DEVELOPMENT = 'PROGRAM_DEVELOPMENT';
export const PUBLIC_POLICY = 'PUBLIC_POLICY';
export const PUBLIC_RELATIONS_COMMUNICATIONS = 'PUBLIC_RELATIONS_COMMUNICATIONS';
export const PUBLIC_SAFETY = 'PUBLIC_SAFETY';
export const PUBLISHING = 'PUBLISHING';
export const RAILROAD_MANUFACTURE = 'RAILROAD_MANUFACTURE';
export const RANCHING = 'RANCHING';
export const REAL_ESTATE = 'REAL_ESTATE';
export const RECREATIONAL_FACILITIES_SERVICES = 'RECREATIONAL_FACILITIES_SERVICES';
export const RELIGIOUS_INSTITUTIONS = 'RELIGIOUS_INSTITUTIONS';
export const RENEWABLES_ENVIRONMENT = 'RENEWABLES_ENVIRONMENT';
export const RESEARCH = 'RESEARCH';
export const RESTAURANTS = 'RESTAURANTS';
export const RETAIL = 'RETAIL';
export const SECURITY_INVESTIGATIONS = 'SECURITY_INVESTIGATIONS';
export const SEMICONDUCTORS = 'SEMICONDUCTORS';
export const SHIPBUILDING = 'SHIPBUILDING';
export const SPORTING_GOODS = 'SPORTING_GOODS';
export const SPORTS = 'SPORTS';
export const STAFFING_RECRUITING = 'STAFFING_RECRUITING';
export const SUPERMARKETS = 'SUPERMARKETS';
export const TELECOMMUNICATIONS = 'TELECOMMUNICATIONS';
export const TEXTILES = 'TEXTILES';
export const THINK_TANKS = 'THINK_TANKS';
export const TOBACCO = 'TOBACCO';
export const TRANSLATION_LOCALIZATION = 'TRANSLATION_LOCALIZATION';
export const TRANSPORTATION_TRUCKING_RAILROAD = 'TRANSPORTATION_TRUCKING_RAILROAD';
export const UTILITIES = 'UTILITIES';
export const VENTURE_CAPITAL_PRIVATE_EQUITY = 'VENTURE_CAPITAL_PRIVATE_EQUITY';
export const VETERINARY = 'VETERINARY';
export const WAREHOUSING = 'WAREHOUSING';
export const WHOLESALE = 'WHOLESALE';
export const WINE_SPIRITS = 'WINE_SPIRITS';
export const WIRELESS = 'WIRELESS';
export const WRITING_EDITING = 'WRITING_EDITING';
export const PREFER_NOT_TO_SAY = 'PREFER_NOT_TO_SAY';