import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["locations", "reviewSummary"],
  _excluded2 = ["reviewSummary"];
import { createImmutableObjectClass } from 'immutable-less';
import produce from 'immer';
import { OfficeLocation } from './OfficeLocation';
import { NONE } from '../constants/Budget';
const profileListingDefaults = {
  updatedAt: null,
  updatedByFullName: null,
  budget: NONE,
  catalogServices: [],
  companySizes: [],
  credentials: [],
  createdAt: null,
  description: '',
  id: 0,
  industries: [],
  languages: [],
  locations: [],
  locationOrder: [],
  logoUrl: '',
  name: '',
  partnerType: '',
  portalId: null,
  regions: [],
  reviewSummary: {},
  slug: '',
  socialScreenshots: {},
  supportedAppOfferings: [],
  tier: '',
  websiteUrl: ''
};
export class ProfileListing extends createImmutableObjectClass(profileListingDefaults, {
  fromJSON: _ref => {
    let {
        locations,
        reviewSummary
      } = _ref,
      rest = _objectWithoutPropertiesLoose(_ref, _excluded);
    return Object.assign({
      locations: locations.map(location => new OfficeLocation(location)),
      reviewSummary: new Map(Object.entries(reviewSummary))
    }, rest);
  },
  toJSON: _ref2 => {
    let {
        reviewSummary
      } = _ref2,
      rest = _objectWithoutPropertiesLoose(_ref2, _excluded2);
    return Object.assign({
      reviewSummary: Object.fromEntries(reviewSummary)
    }, rest);
  }
}) {
  isEmpty() {
    return Object.keys(profileListingDefaults).every(key => {
      if (key === 'reviewSummary') {
        return this.get('reviewSummary').keys.length === 0;
      }
      if (key === 'locations') {
        return this.get('locations').length === 0;
      }
      return this.get(key) === profileListingDefaults[key];
    });
  }
  updateProfileListing(updates) {
    return produce(this, profileListingDraft => {
      Object.entries(updates).forEach(([key, value]) => {
        if (key === 'reviewSummary') {
          Object.entries(value).forEach(([updateKey, updateValue]) => {
            profileListingDraft.get(key).set(updateKey, updateValue);
          });
        } else {
          profileListingDraft.set(key, value);
        }
      });
    });
  }
}